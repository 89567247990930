import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
	selector: 'expansion-panel',
	templateUrl: './expansion-panel.component.html',
	styleUrls: ['./expansion-panel.component.scss'],
	host: { 'style': 'display: block;border-bottom: 1px solid #333;' }
})
export class ExpansionPanelComponent implements OnInit {
	
	@Input() InnerFormGroup: FormGroup
	@Input() OnlyView: boolean = false
	@Input() loading: boolean = false
	types = [{ value: 'radio', text: 'Única Resposta' }, { value: 'checkbox', text: 'Múltiplas Respostas' }]
	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	@Output() callback: EventEmitter<any>
	@Output() update: EventEmitter<any>

	constructor() {
		this.callback = new EventEmitter()
		this.update = new EventEmitter()
	}

	ngOnInit() {}

	deleteSpecification(): void {
		this.callback.emit(this.InnerFormGroup)
	}

	addChip(event: MatChipInputEvent): void {
		const value = (event.value || '').trim()
		if (value) this.InnerFormGroup.get('options').value.push(value)
		event.chipInput!.clear()
	  }
	
	removeChip(option: string): void {
		let auxOpt = this.InnerFormGroup.get('options').value
		auxOpt.splice(auxOpt.indexOf(option), 1)
	}

	updateSpecification(): void {
		this.InnerFormGroup.markAllAsTouched()
		
		if (!this.InnerFormGroup.get('options').value || this.InnerFormGroup.get('options').value.length == 0)
			this.InnerFormGroup.get('options').setErrors({ required: true })
		else
			this.InnerFormGroup.get('options').setErrors(null)

		if (this.InnerFormGroup.valid)
			this.update.emit(this.InnerFormGroup)
	}

}