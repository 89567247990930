export class Specification {

	id: string
    title: string
    question: Question
    createdAt: string
    updatedAt: string
    
	constructor(json?: any) {
        if (typeof json === 'string') {
            this.id = json
        } else if (json) {
            this.id = json.id
            this.title = json.title
            this.createdAt = json.createdAt
            this.updatedAt = json.updatedAt
            
            this.question = {
                description: json.question.description,
                type: json.question.type.toLowerCase(),
                options: json.question.options
            }
        }
    }

}

export class Question {

    description: string
    type: string
    options: string[]

}