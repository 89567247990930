import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EventEmitterService } from '@app/_services/event-emitter.service';
import { PreOrderService } from '@app/_services/pre-order.service';
import { QuizService } from '@app/_services/quiz.service';
import { FormControl, FormGroup } from '@angular/forms';
import { PreOrderSmall } from '@app/_models/preordersmall';
import { ConfirmService } from '@app/_services/confirm.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv';

@Component({
	selector: 'pre-orders',
	templateUrl: './pre-orders.component.html',
	styleUrls: ['./pre-orders.component.scss'],
	encapsulation: ViewEncapsulation.None,
	host: { 'style': 'display: block; height: calc(100% - 50px);' }
})
export class PreOrdersComponent implements OnInit {

	preOrders: PreOrderSmall[] = []
	displayedColumns = ['clientName', 'model', 'version', 'category', 'status', 'createdAt']
  	dataSource: any

	categories = [ { value: 1, text: 'Truck' }, { value: 2, text: 'Furgão' }, { value: 3, text: 'Van' } ]
	status = [
		{ value: 0, text: 'Qualquer' },
		{ value: 1, text: 'Abandonado' },
		{ value: 2, text: 'Aguardando' },
		{ value: 3, text: 'Em Contato' },
		{ value: 4, text: 'Rejeitado' },
		{ value: 5, text: 'Fechado' },
	]
	
	loading: boolean = false
	states: any[] = []
	cities: string[] = []

	filtersFormGroup: FormGroup
	selectedFilters: any
	showFiltersModal: boolean = false

	showDetailsModal: boolean = false
	currentDetail: any = {}
	statusSelectFormControl: FormControl = new FormControl(0, [])
	firstShowStatusSelect: boolean = true
	
	constructor(
		private _router: Router,
		private _preOrderService: PreOrderService,
		private _quizService: QuizService,
		private _confirmService: ConfirmService
	) {
		this.selectedFilters = {
			'status': 0,
			'anyLocation': true,
			'state': '',
			'city': '',
			'anyPeriod': true,
			'dateStart': '',
			'dateEnd': ''
		};

		this._setupFormGroup();
	}

	ngOnInit(): void {
		EventEmitterService.get('showLoading').emit(true)
		this.getPreOrderData()
		this.states = this._quizService.getStates()

		this.statusSelectFormControl
			.valueChanges
			.subscribe((value) => {
				if (!this.firstShowStatusSelect) {
					this.changeStatusForPreOrder(value, this.currentDetail.id)
				}
			})
	}

	getCities() {
		let state = this.filtersFormGroup.get('state').value

		if (!state)
			return []

		let item = this.states.find(x => x.state == state)

		if (!item)
			return []

		return item.cities;
	}

	private _setupFormGroup() {
		this.filtersFormGroup = new FormGroup({
			'status': new FormControl(0, []),
			'anyLocation': new FormControl(true, []),
			'state': new FormControl('', []),
			'city': new FormControl('', []),
			'anyPeriod': new FormControl(true, []),
			'dateStart': new FormControl('', []),
			'dateEnd': new FormControl('', []),
		})
	}

	getPreOrderData() {
		EventEmitterService.get('showLoading').emit(true)

		let queryParams = []

		if (this.selectedFilters.status)
			queryParams.push(`status=${this.getStatusLabel(this.selectedFilters.status, true)}`)
		
		if (!this.selectedFilters.anyLocation) {
			if (this.selectedFilters.state)
				queryParams.push(`state=${this.selectedFilters.state}`)
			
			if (this.selectedFilters.city)
				queryParams.push(`city=${this.selectedFilters.city}`)
		}
		
		if (!this.selectedFilters.anyPeriod) {
			if (this.selectedFilters.dateStart)
				queryParams.push(`startDate=${this.selectedFilters.dateStart}`)
			
			if (this.selectedFilters.dateStart && this.selectedFilters.dateEnd)
				queryParams.push(`endDate=${this.selectedFilters.dateEnd}`)
		}

		this._preOrderService
			.list(encodeURI(queryParams.join('&')))
			.subscribe(response => {
				if (response.success) {
					this.preOrders = response.data ? response.data.map((x: any) => new PreOrderSmall(x)) : []
					this.dataSource = new MatTableDataSource(this.preOrders)
				}

				EventEmitterService.get('showLoading').emit(false)
			})
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	goBack() {
		this._router.navigate(['/'])
	}

	getCategoryLabel(value: number): string {
		let category = this.categories.find(x => x.value == value)
		return category ? category.text : value.toString()
	}

	getStatusLabel(value: number, originalName: boolean = false): string {
		switch(value) {
			case 0:
				return !originalName ? 'Qualquer' : 'Any'
			case 1:
				return !originalName ? 'Abandonado' : 'Abandoned'
			case 2:
				return !originalName ? 'Aguardando' : 'Waiting'
			case 3:
				return !originalName ? 'Em Contato' : 'InContact'
			case 4:
				return !originalName ? 'Rejeitado' : 'Rejected'
			case 5:
				return !originalName ? 'Fechado' : 'Closed'
			default:
				return `${value}`
		}
	}

	getStatusValue(status: string): number {
		switch(status.toLocaleLowerCase()) {
			case 'abandoned':
				return 1
			case 'waiting':
				return 2
			case 'incontact':
				return 3
			case 'rejected':
				return 4
			case 'closed':
				return 5
			default:
				return 0
		}
	}

	openFiltersModal() {
		const s = this.selectedFilters.dateStart;
		const f = this.selectedFilters.dateEnd;
		let sdate = new Date(s.substr(0, 4), parseInt(s.substr(5, 2)) - 1, s.substr(8, 2));
		let fdate = new Date(f.substr(0, 4), parseInt(f.substr(5, 2)) - 1, f.substr(8, 2));

		(this.filtersFormGroup.get('status') as FormControl).setValue(this.selectedFilters.status);
		(this.filtersFormGroup.get('anyLocation') as FormControl).setValue(this.selectedFilters.anyLocation);
		(this.filtersFormGroup.get('state') as FormControl).setValue(this.selectedFilters.state);
		(this.filtersFormGroup.get('city') as FormControl).setValue(this.selectedFilters.city);
		(this.filtersFormGroup.get('anyPeriod') as FormControl).setValue(this.selectedFilters.anyPeriod);
		(this.filtersFormGroup.get('dateStart') as FormControl).setValue(sdate);
		(this.filtersFormGroup.get('dateEnd') as FormControl).setValue(fdate);

		this.showFiltersModal = true;
	}

	closeFiltersModal() {
		this._confirmService
			.confirm('Fechar Filtros', 'Tem certeza que deseja fechar os filtros?')
			.then((confirmed) => {
				if (confirmed) {
					this.showFiltersModal = false;
					this._setupFormGroup()
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	applyFilters() {
		let data = this.filtersFormGroup.value
		const start = data.dateStart as Date
		const final = data.dateEnd as Date

		if (!data.anyPeriod && data.dateStart && start instanceof Date && !isNaN(start.valueOf())) {
			const sday = start.getDate().toString()
			const smonth = (start.getMonth() + 1).toString()
			data.dateStart = `${start.getFullYear()}-${smonth.length == 1 ? '0' + smonth : smonth}-${sday.length == 1 ? '0' + sday : sday}`
		} else {
			data.dateStart = ''
		}
		
		if (!data.anyPeriod && data.dateStart && data.dateEnd && start < final && final instanceof Date && !isNaN(final.valueOf())) {
			const fday = final.getDate().toString()
			const fmonth = (final.getMonth() + 1).toString()
			data.dateEnd = `${final.getFullYear()}-${fmonth.length == 1 ? '0' + fmonth : fmonth}-${fday.length == 1 ? '0' + fday : fday}`
		} else {
			data.dateEnd = ''
		}

		this.selectedFilters = data
		this.getPreOrderData()
		this.showFiltersModal = false
	}

	cleanFilters() {
		this._setupFormGroup()
		let data = this.filtersFormGroup.value
		this.selectedFilters = data
		this.getPreOrderData()
		this.showFiltersModal = false
	}

	exportCsv() {
		if (!this.preOrders.length) {
			EventEmitterService.get('showAlert').emit({ icon: 'highlight_off', type: 'error', title: 'Atenção!', content: 'Não existem registros para exportar de acordo com o filtro selecionado.', reload: false })
			return
		}
		let data = this.preOrders.map((pre) => {
			debugger
			var day = pre.createdAt.getDate().toString();
			var month = pre.createdAt.getMonth().toString();
			return {
				'clientName': pre.customerName,
				'model': pre.vehicleModel,
				'version': pre.vehicleVersion,
				'category': pre.vehicleCategory,
				'status': this.getStatusLabel(this.getStatusValue(pre.status)),
				'createdAt': `${day.length == 1 ? '0' + day : day}/${month.length == 1 ? '0' + month : month}/${pre.createdAt.getFullYear()}`
			}
		})
		
		new AngularCsv(data, "relatorio_pedidos", {
			fieldSeparator: ';',
			quoteStrings: '"',
			decimalseparator: ',',
			showLabels: true,
			showTitle: true,
			title: '',
			useBom: true,
			noDownload: false,
			headers: ['Cliente', 'Modelo', 'Versão', 'Categoria', 'Status', 'Criado Em']
		})
	}

	openDetailsModal(element: PreOrderSmall) {
		EventEmitterService.get('showLoading').emit(true)
		this.showDetailsModal = true

		this._preOrderService
			.get(element.id)
			.subscribe(response => {
				console.log(response)
				if (response.success) {
					this.currentDetail = response.data
					this.statusSelectFormControl.setValue(this.getStatusValue(response.data.status))
					this.firstShowStatusSelect = false
				} else {
					EventEmitterService.get('showAlert').emit({ icon: 'highlight_off', type: 'error', title: 'Atenção!', content: 'Detalhes do pedido não encontrados', reload: false })
					this.showDetailsModal = false
				}

				EventEmitterService.get('showLoading').emit(false)
			})
	}

	closeDetailsModal() {
		this.showDetailsModal = false
		this.firstShowStatusSelect = true
		const preOrderIndex = this.preOrders.findIndex(x => x.id == this.currentDetail.id)

		if (preOrderIndex >= 0) {
			this.preOrders[preOrderIndex].status = this.getStatusLabel(this.statusSelectFormControl.value, true)
			this.dataSource.data[preOrderIndex].status = this.getStatusLabel(this.statusSelectFormControl.value, true)
			this.dataSource._updateChangeSubscription()
		}
	}

	getOnlyValidStatusList() {
		return this.status.filter(x => x.value > 0)
	}

	private changeStatusForPreOrder(newValue: number, preOrderId: string) {
		this._preOrderService
			.patch(preOrderId, this.getStatusLabel(newValue, true))
			.subscribe(_ => {})
	}

}