<section id="preOrders">
	<header class="page-header">
		<mat-icon class="material-icons-outlined back" (click)="goBack()" style="cursor: pointer;">keyboard_arrow_left</mat-icon>
		<div class="title">
			<mat-icon class="material-icons-outlined">receipt_long</mat-icon>
			<span>Pedidos</span>
		</div>
		<div></div>
	</header>

	<!-- TOOLS SECTIONS -->
	<section class="tools">
		<div class="filters">
			<label class="lbl">Filtros</label>
			<div class="items">
				<div class="bdgs">
					<span>
						Status: {{ getStatusLabel(selectedFilters.status) }}
					</span>
					
					<span *ngIf="selectedFilters.anyLocation || !selectedFilters.anyLocation && !selectedFilters.state">
						Todas as Localizações
					</span>
					<span *ngIf="!selectedFilters.anyLocation && selectedFilters.state">
						{{ selectedFilters.city ? selectedFilters.city + ' / ' + selectedFilters.state : selectedFilters.state }}
					</span>

					<span *ngIf="selectedFilters.anyPeriod || !selectedFilters.anyPeriod && !selectedFilters.dateStart">
						Qualquer Período
					</span>
					<span *ngIf="!selectedFilters.anyPeriod && selectedFilters.dateStart && !selectedFilters.dateEnd">
						De {{ selectedFilters.dateStart | date: 'dd/MM/yy' }} até hoje
					</span>
					<span *ngIf="!selectedFilters.anyPeriod && selectedFilters.dateStart && selectedFilters.dateEnd">
						De {{ selectedFilters.dateStart | date: 'dd/MM/yy' }} até {{ selectedFilters.dateEnd | date: 'dd/MM/yy' }}
					</span>
				</div>
				<div class="btn-change-filters" matTooltip="Editar Filtros" (click)="openFiltersModal()">
					<mat-icon class="material-icons-outlined">tune</mat-icon>
				</div>
			</div>
		</div>

		<div>
			<mat-form-field>
				<mat-label>Pesquisar</mat-label>
				<input matInput placeholder="Pesquisar" autocomplete="off" (keyup)="applyFilter($event)">
			</mat-form-field>
	
			<btn icon="ios_share" text="Exportar" color="blue" (callback)="exportCsv()"></btn>
		</div>
	</section>
	
	<!-- LIST TABLE -->
	<section class="table-container table-responsive" *ngIf="preOrders.length">
		<table mat-table [dataSource]="dataSource">
		  	<ng-container matColumnDef="clientName">
				<th mat-header-cell *matHeaderCellDef>Cliente</th>
				<td mat-cell *matCellDef="let elmt" (click)="openDetailsModal(elmt)">
					{{ elmt.customerName }}
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="model">
				<th mat-header-cell *matHeaderCellDef class="text-center">Modelo</th>
				<td mat-cell *matCellDef="let elmt" (click)="openDetailsModal(elmt)" class="text-center">
					{{ elmt.vehicleModel }}
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="version">
				<th mat-header-cell *matHeaderCellDef class="text-center">Versão</th>
				<td mat-cell *matCellDef="let elmt" (click)="openDetailsModal(elmt)" class="text-center">
					{{ elmt.vehicleVersion }}
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="category">
				<th mat-header-cell *matHeaderCellDef class="text-center">Categoria</th>
				<td mat-cell *matCellDef="let elmt" (click)="openDetailsModal(elmt)" class="text-center">
					{{ getCategoryLabel(elmt.vehicleCategory) }}
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef class="text-center">Status</th>
				<td mat-cell *matCellDef="let elmt" (click)="openDetailsModal(elmt)" class="text-center">
					{{ getStatusLabel(getStatusValue(elmt.status), false) }}
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="createdAt">
				<th mat-header-cell *matHeaderCellDef class="text-center">Criado Em</th>
				<td mat-cell *matCellDef="let elmt" (click)="openDetailsModal(elmt)" class="text-center">
					{{ elmt.createdAt | date : 'dd/MM/yyyy' }}
				</td>
		  	</ng-container>
	  
		  	<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</section>

	<!-- EMPTY MESSAGE -->
	<section class="empty" *ngIf="!preOrders.length">
		Nenhum pedido encontrado com os filtros informados
	</section>

	<!-- MODAL FILTERS -->
	<section class="modal-container" *ngIf="showFiltersModal">
		<div class="filter-modal" id="mod1">
			<header>
				<mat-icon class="icon material-icons-outlined">tune</mat-icon>
				<h1>Edição de Filtros</h1>
				<mat-icon class="closing material-icons-outlined" (click)="closeFiltersModal()">close</mat-icon>
			</header>

			<div class="content">
				<form style="padding-top: 20px;" [formGroup]="filtersFormGroup">					
					<div class="form-container" style="display: flex;justify-content: space-between;">
						<!-- STATUS -->
						<mat-form-field style="width: 150px;flex-grow: unset;">
							<mat-label>Status</mat-label>
							<mat-select formControlName="status">
								<mat-option *ngFor="let stat of status" [value]="stat.value">
									{{ stat.text }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<!-- ANY LOCATION -->
						<mat-checkbox formControlName="anyLocation">Qualquer Localidade</mat-checkbox>
						
						<!-- ANY PERIOD -->
						<mat-checkbox formControlName="anyPeriod">Qualquer Período</mat-checkbox>
					</div>
					
					<div class="form-container" *ngIf="!filtersFormGroup.get('anyLocation').value">
						<!-- STATE -->
						<mat-form-field>
							<mat-label>Estado</mat-label>
							<mat-select formControlName="state">
								<mat-option value="">Estado</mat-option>
								<mat-option *ngFor="let uf of states" [value]="uf.state">
									{{ uf.state }}
								</mat-option>
							</mat-select>
						</mat-form-field>

						<!-- CITY -->
						<mat-form-field>
							<mat-label>Cidade</mat-label>
							<mat-select formControlName="city" [disabled]="!filtersFormGroup.get('state').value">
								<mat-option value="">Cidade</mat-option>
								<mat-option *ngFor="let city of getCities()" [value]="city">
									{{ city }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div class="form-container" *ngIf="!filtersFormGroup.get('anyPeriod').value">
						<!-- START DATE -->
						<mat-form-field>
							<mat-label>Data Inicial</mat-label>
							<input matInput [matDatepicker]="dp1" formControlName="dateStart" disabled>
							<mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
							<mat-datepicker class="fix-colors-datepicker" #dp1 disabled="false"></mat-datepicker>
						</mat-form-field>

						<!-- END DATE -->
						<mat-form-field>
							<mat-label>Data Final</mat-label>
							<input matInput [matDatepicker]="dp2" formControlName="dateEnd" disabled>
							<mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
							<mat-datepicker class="fix-colors-datepicker" #dp2 disabled="false"></mat-datepicker>
						</mat-form-field>
					</div>					
				</form>
			</div>

			<footer>
				<div style="flex-grow: 1;">
					<button type="button" class="cancel-btn" mat-stroked-button (click)="cleanFilters()" [disabled]="loading">
						<mat-icon class="material-icons-outlined">cleaning_services</mat-icon>
						<span>Limpar Filtros</span>
					</button>
				</div>
				<button type="button" class="cancel-btn" mat-stroked-button (click)="closeFiltersModal()" [disabled]="loading">
					<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
					<span>Cancelar</span>
				</button>
				<button class="save-btn" mat-flat-button (click)="applyFilters()" [class.spinner]="loading" [disabled]="loading">
					<span [class.opac]="loading">Aplicar</span>
					<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
				</button>
			</footer>
		</div>
	</section>

	<section class="modal-container" *ngIf="showDetailsModal">
		<div class="filter-modal" id="mod1">
			<header>
				<mat-icon class="icon material-icons-outlined">receipt_long</mat-icon>
				<h1>Detalhes de Pedido</h1>
				<mat-icon class="closing material-icons-outlined" (click)="closeDetailsModal()">close</mat-icon>
			</header>

			<div class="content">
				<mat-tab-group>
					<mat-tab label="Pedido">
						<div class="infos" style="margin: 0;">
							<div class="box" style="margin-bottom: 20px;">
								<div class="item lg">
									<label>Nome do Cliente</label>
									<span>{{ currentDetail.customer.name }}</span>
								</div>
								<div class="item lg">
									<label>E-mail</label>
									<span>{{ currentDetail.customer.email }}</span>
								</div>
								<div class="item lg">
									<label>Telefone/Celular</label>
									<span *ngIf="currentDetail.customer.phone">{{ currentDetail.customer.phone | mask: currentDetail.customer.phone.length < 11 ? '(00) 0000-0000' : '(00) 0 0000-0000' }}</span>
									<span *ngIf="!currentDetail.customer.phone">-</span>
								</div>
							</div>
							<div class="box" style="margin-bottom: 20px;">
								<div class="item lg">
									<label>Localidade</label>
									<span>{{ currentDetail.city + ' / ' + currentDetail.state }}</span>
								</div>
							</div>
							<div class="box">
								<div class="item lg">
									<label>Status do Pedido</label>
									<mat-form-field class="fix-select-detail">
										<mat-select [formControl]="statusSelectFormControl">
											<mat-option *ngFor="let stat of getOnlyValidStatusList()" [value]="stat.value">
												{{ stat.text }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<div class="item" style="width: 107px;">
									<label>Data de Criação</label>
									<span>{{ currentDetail.createdAt.substr(0, 10) }}</span>
								</div>
							</div>
						</div>
					</mat-tab>
					<mat-tab label="Veículo">
						<div class="tab-vehicle-detail">
							<div class="head-info">
								<img [src]="currentDetail.vehicle.image" alt="Vehicle Image" />
								<div class="infos">
									<div class="box" style="margin-bottom: 20px;">
										<div class="item">
											<label>Modelo</label>
											<span>{{ currentDetail.vehicle.model }}</span>
										</div>
										<div class="item">
											<label>Versão</label>
											<span>{{ currentDetail.vehicle.version }}</span>
										</div>
										<div class="item">
											<label>Categoria</label>
											<span>{{ currentDetail.vehicle.category }}</span>
										</div>
									</div>
									<div class="box">
										<div class="item">
											<label>Preço</label>
											<span *ngIf="currentDetail.vehicle.price">{{ currentDetail.vehicle.price | currency : 'R$' }}</span>
											<span *ngIf="!currentDetail.vehicle.price">-</span>
										</div>
										<div class="item">
											<label>Entrada</label>
											<span *ngIf="currentDetail.vehicle.entryPrice">{{ currentDetail.vehicle.entryPrice | currency : 'R$' }}</span>
											<span *ngIf="!currentDetail.vehicle.entryPrice">-</span>
										</div>
										<div class="item">
											<label>ICMS</label>
											<span *ngIf="currentDetail.vehicle.icms">{{ currentDetail.vehicle.icms | currency : 'R$' }}</span>
											<span *ngIf="!currentDetail.vehicle.icms">-</span>
										</div>
										<!-- <span>{{ currentDetail.vehicle.installment }}</span> -->
									</div>
								</div>
							</div>
							<ul class="specs">
								<li *ngFor="let spec of currentDetail.vehicle.specifications" [matTooltip]="spec.title + ': ' + spec.value">
									{{ spec.title + ': ' + spec.value }}
								</li>
							</ul>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>

			<footer>
				<button type="button" class="cancel-btn" mat-stroked-button (click)="closeDetailsModal()" [disabled]="loading">
					<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
					<span>Fechar</span>
				</button>
			</footer>
		</div>
	</section>
</section>