import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlertConfig } from '@app/_common/alert/alert.model';
import { User } from '@app/_models/user';
import { ConfirmService } from '@app/_services/confirm.service';
import { EventEmitterService } from '@app/_services/event-emitter.service';
import { ImageService } from '@app/_services/image.service';
import { UserService } from '@app/_services/user.service';

@Component({
	selector: 'users',
	templateUrl: './users.component.html',
	styleUrls: ['./users.component.scss'],
	encapsulation: ViewEncapsulation.None,
	host: { 'style': 'display: block; height: calc(100% - 50px);' }
})
export class UsersComponent implements OnInit {

	displayedColumns = ['name', 'email', 'options']
  	dataSource: any
	showCreationModal: boolean = false
	
	firstFG: FormGroup
	previewImage: string
	file: File

	loading: boolean = false
	
	constructor(
		private _router: Router,
		private _userService: UserService,
		private _imageService: ImageService,
		private _confirmService: ConfirmService) {

		this.firstFG = new FormGroup({
			'name': new FormControl('', [Validators.required]),
			'email': new FormControl('', [Validators.required]),
			'password': new FormControl('', [Validators.required]),
			'image': new FormControl('', [])
		})
	}

	ngOnInit(): void {
		EventEmitterService.get('showLoading').emit(true)
		this.getUsersData()
	}

	private getUsersData() {
		this._userService
			.list()
			.subscribe(response => {
				if (response.success)
					this.dataSource = new MatTableDataSource(response.data.map((x: any) => new User(x)))
				
				EventEmitterService.get('showLoading').emit(false)
			})
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openCreation() {
		this.showCreationModal = true
	}

	cancelCreation() {
		this._confirmService
			.confirm('Fechar Criação', 'Tem certeza que deseja fechar a criação?')
			.then((confirmed) => {
				if (confirmed) {
					this.showCreationModal = false
					this.firstFG.reset('')
					this.previewImage = null
					this.file = null
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	createUser() {
		this.firstFG.markAllAsTouched()
		this.firstFG.get('image').markAsDirty()
		
		if (this.firstFG.valid) {
			this.loading = true

			if (this.firstFG.get('image').value) {
				this._imageService
					.post(this.file)
					.subscribe(response => {
						if (!response.success) {
							EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
							this.loading = false
							return
						}
	
						this.postUser(response.data)
					},
					error => {
						this.loading = false
						console.error(error)
					})
			} else {
				this.postUser('')
			}			
		}
	}

	private postUser(imageLink: string) {
		let user = new User(this.firstFG.value)
		user.image = imageLink

		this._userService
			.post(user)
			.subscribe(response => {
				if (!response.success) {
					EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
					this.loading = false
					return
				}

				this.dataSource.data.push(new User(response.data))
				this.dataSource._updateChangeSubscription()
				this.showCreationModal = false
				this.firstFG.reset('')
				this.previewImage = null
				this.file = null
				this.loading = false
			},
			error => {
				this.loading = false
				console.error(error)
			})
	}

	removeUser(elmnt: User) {
		this._confirmService
			.confirm('Atenção!', 'Tem certeza que deseja remover o usuário?')
			.then((confirmed) => {
				if (confirmed) {
					let index = this.dataSource.data.findIndex((x: User) => x == elmnt)
					
					if (index > -1) {
						this._userService
							.delete(elmnt.id)
							.subscribe(response => {
								if (!response.success) {
									EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
									return
								}
			
								this.dataSource.data.splice(index, 1)
								this.dataSource._updateChangeSubscription()
							})
					}
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	imagesPreview(event) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();

            reader.onload = (_event: any) => {
				if (event.srcElement.files[0]) {
					this.previewImage = _event.target.result
					this.file = event.srcElement.files[0]
					this.firstFG.get('image').setValue(event.srcElement.files[0].name)
				}
            }

            reader.readAsDataURL(event.target.files[0]);
        }
    }

	goBack() {
		this._router.navigate(['/'])
	}

}