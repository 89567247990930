import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'inpt',
	templateUrl: './inpt.component.html',
	styleUrls: ['./inpt.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class InptComponent implements OnInit {
	
	@Input() label: string
	@Input() type: string
	@Input() prefix: string
	@Input() suffix: string
	@Input() theme: string
	
	@Input() inputValue: string
	@Output() inputValueChange: EventEmitter<any>

	@Output() suffixCallback: EventEmitter<any>

	inputFM: FormControl
	focused: boolean
	hint: string
	showEmailCheck: boolean
	showPassViewer: boolean

	constructor() {
		this.suffixCallback = new EventEmitter();
		this.inputValueChange = new EventEmitter();
		this.theme = 'default'
		this.showEmailCheck = false
		this.showPassViewer = false
		this.focused = false
	}

	ngOnInit(): void {
		let validators = [Validators.required]

		if (this.type == 'email')
			validators.push(Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))

		this.inputFM = new FormControl(this.inputValue, validators)
		this.inputFM
			.valueChanges
			.subscribe(change => {
				this.theme = this.inputFM.errors ? 'error' : 'focus'
				this.inputValue = change
				this.inputValueChange.emit(this.inputValue)
			})
	}

	ngOnChanges(changes: SimpleChanges) {
		this.theme = this.inputFM && this.inputFM.errors ? 'error' : this.focused ? 'focus' : 'default'
		this.setHints()

		if (changes.inputValue) {
			if (this.type == 'email')
				this.showEmailCheck = changes.inputValue.currentValue && this.validateEmail(changes.inputValue.currentValue)
	
			if (this.type == 'password')
				this.showPassViewer = changes.inputValue.currentValue && changes.inputValue.currentValue.length > 0
		}
    }

	suffixClick(): void {
		this.suffixCallback.emit([])
	}
	
	onFocus() {
		this.focused = true
		this.theme = this.inputFM.errors ? 'error' : 'focus'
		this.setHints()
	}
	
	onBlur() {
		this.focused = false
		this.theme = this.inputFM.errors ? 'error' : 'default'
		this.setHints()
	}

	private setHints() {
		if (this.inputFM && this.inputFM.errors) {
			if (this.inputFM.errors.required)
				switch (this.type) {
					case 'email':
						this.hint = 'Digite seu e-mail'
						break
					case 'password':
						this.hint = 'Digite sua senha'
						break
					default:
						this.hint = 'Este campo não pode ser vazio'
				}

			if (this.inputFM.errors.pattern)
				switch (this.type) {
					case 'email':
						this.hint = 'E-mail inválido'
						break
					default:
						this.hint = 'Campo inválido'
				}
		}
	}

	private validateEmail(mail: string): boolean {
		return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail) && mail.indexOf('.') > -1
	}
	
}