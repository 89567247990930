import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { EventEmitterService } from "@app/_services/event-emitter.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	constructor(private authenticationService: AuthenticationService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(err => {
			if (err.status == 401) {
				EventEmitterService.get('showAlert').emit({ icon: 'highlight_off', type: 'error', title: 'Ocorreu um erro', content: err.error && err.error.errors && err.error.errors.length ? err.error.errors.join(', ') : 'Você não possui a permissão necessária para esta requisição', reload: false })
				this.authenticationService.logout()
			} else if (err)
				EventEmitterService.get('showAlert').emit({ icon: 'highlight_off', type: 'error', title: 'Ocorreu um erro', content: err.error && err.error.errors && err.error.errors.length ? err.error.errors.join(', ') : 'Não foi possível completar sua requisição', reload: false })
			
			const error = err && err.error ? err.error.message || err.statusText : 'Não foi possível completar sua requisição'
			return throwError(error)
		}))
	}

}