import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/_services/authentication.service';

@Component({
	selector: 'menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {
	
	@Output() callback: EventEmitter<any>
	@Input() userName: string
	@Input() userEmail: string
	@Input() userImage: string
	@Input() menuOpened: boolean

	menu: { icon: string, title: string, path: string }[]

	constructor(private _router: Router, private _authenticationService: AuthenticationService) {
		this.callback = new EventEmitter();
	}

	ngOnInit(): void {
		this.menu = [
			{ icon: 'fact_check', title: 'Especificações', path: 'specifications' },
			{ icon: 'account_balance', title: 'Financeiro', path: 'financial' },
			{ icon: 'commute', title: 'Veículos', path: 'vehicles' },
			{ icon: 'quiz', title: 'Questionários', path: 'quizzes' },
			// { icon: 'settings', title: 'Configurações', path: '' }
			{ icon: 'person', title: 'Usuários', path: 'users' },
			{ icon: 'receipt_long', title: 'Pedidos', path: 'pre-orders' },
		]
	}

	goTo(index: number): void {
		this._router.navigate([`/${this.menu[index].path}`])
		this.callback.emit([])
	}

	close() {
		this.callback.emit([])
	}

	logout() {
		this._authenticationService.logout()
	}
	
}