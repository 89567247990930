<div class="confirm">
	<div class="head">
		<mat-icon class="icon">warning</mat-icon>
		<label class="title">{{ title }}</label>
		<div class="closing">
			<mat-icon (click)="dismiss()">close</mat-icon>
		</div>
	</div>
	<p class="content">{{ message }}</p>
	<div class="btns">
		<button type="button" class="cancel-btn" mat-stroked-button (click)="decline()">
			<span>{{ btnCancelText }}</span>
		</button>
		<button type="button" class="save-btn" mat-flat-button (click)="accept()" id="confirm-btn-text">
			<span>{{ btnOkText }}</span>
		</button>
	</div>
</div>
<div class="back"></div>