import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs/internal/Observable";

@Injectable({ providedIn: 'root' })
export class PreOrderService {

	constructor(private http: HttpClient) {}

	list(queryParams: string): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/preorder/report${queryParams ? '?' + queryParams : ''}`)
	}

	get(id: string): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/preorder/${id}`)
	}

	patch(id: string, status: string): Observable<any> {
		return this.http.patch<any>(`${environment.apiUrl}/preorder/${id}`, { status })
	}

}