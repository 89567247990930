import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '@app/_services/authentication.service';
import { EventEmitterService } from '@app/_services/event-emitter.service';
import { UserService } from '@app/_services/user.service';

@Component({
	selector: 'login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	
	@Output() loginCallback: EventEmitter<string>
	
	email: string	
	password: string
	showPass: boolean

	loading: boolean = false

	constructor(private _authenticationService: AuthenticationService, private _userService: UserService) {
		this.showPass = false
		this.loginCallback = new EventEmitter<string>()
	}

	ngOnInit(): void {}

	showPassword(): void {
		this.showPass = !this.showPass
	}

	login(): void {
		if (!this.email || !this.password) {
			// Atribuições realizadas de forma a disparar erro no input pelo change
			if (!this.email) this.email = ""
			if (!this.password) this.password = ""
			return
		}

		this.loading = true

		this._authenticationService
			.login(this.email, this.password)
			.subscribe(
				response => {
					if (!response.success) {
						EventEmitterService.get('showAlert').emit({ icon: 'highlight_off', type: 'error', title: 'Atenção!', content: response.errors.join(', '), reload: false })
						return
					}
					
					this.loginCallback.emit(response.user.image)
					
					localStorage.setItem('user', this.email)
					localStorage.setItem('expiration', response.data.expired)
					localStorage.setItem('token', response.data.token)
					localStorage.setItem('userName', response.user.name)
					localStorage.setItem('userImage', response.user.image)
					EventEmitterService.get('showMessage').emit(true)
					this.loading = false
				},
				error => {
					this.loading = false
					console.error(error)
				}
			)
	}
	
}