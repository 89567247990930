import { Component } from '@angular/core';
import { User } from './_models/user';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from './_services/authentication.service';
//import { Property } from './_models/property';
//import { PropertyService } from './_services/property.service';
import { filter } from 'rxjs/operators';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
	
	public currentUser: User
	//public currentProperty: Property
	private prevRoute: string

	constructor(
		private router: Router,
		private authenticationService: AuthenticationService,
		//private propertyService: PropertyService
	) {
		this.authenticationService.currentUser.subscribe(x => this.currentUser = x)
		//this.currentProperty = this.propertyService.currentProperty

		this.router.events
		.pipe(filter(event => event instanceof NavigationEnd))
		.subscribe(({urlAfterRedirects}: NavigationEnd) => {
			//if (this.prevRoute && (this.prevRoute.indexOf('/login') > -1 || this.prevRoute.indexOf('/select-property') > -1))
				//this.currentProperty = this.propertyService.currentProperty

			this.prevRoute = urlAfterRedirects
		});
	}

	/*removeCurrentProperty() {
		localStorage.removeItem('currentProperty')
		this.currentProperty = null
	}*/

	logout() {
		this.authenticationService.logout()
		//this.currentProperty = null
		this.router.navigate(['/login'])
	}
	
}