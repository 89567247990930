<section id="vehicles">
	<header class="page-header">
		<mat-icon class="material-icons-outlined back" (click)="goBack()" style="cursor: pointer;">keyboard_arrow_left</mat-icon>
		<div class="title">
			<mat-icon class="material-icons-outlined">quiz</mat-icon>
			<span>Questionários</span>
		</div>
		<div></div>
	</header>

	<section class="tools">
		<mat-form-field>
			<mat-label>Pesquisar</mat-label>
			<input matInput placeholder="Pesquisar" autocomplete="off" (keyup)="applyFilter($event)">
		</mat-form-field>
		<btn icon="add" text="Novo" color="blue" (callback)="openCreationQuiz()"></btn>
	</section>
	
	<section class="table-container">
		<table mat-table [dataSource]="dataSource">
		  	<ng-container matColumnDef="title">
				<th mat-header-cell *matHeaderCellDef>Questionário</th>
				<td mat-cell *matCellDef="let elmt" style="cursor: pointer;display: flex;align-items: center;align-content: center;height: 50px;">
					<p (click)="detailQuiz(elmt)" style="margin: 0;">
						{{ elmt.title }}
						<br/>
						<span style="font-size: 12px;color: #DDD;">
							<strong style="color: inherit;">Caminho: </strong><i style="color: inherit;">{{ elmt.path }}</i> | <strong style="color: inherit;">Categoria: </strong><i style="color: inherit;">{{ elmt.vehicleCategory }}</i>
						</span>
					</p>
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="options">
				<th mat-header-cell *matHeaderCellDef style="text-align: right;">Opções</th>
				<td mat-cell *matCellDef="let elmt" class="options-td">
					<button mat-icon-button [matMenuTriggerFor]="submenu">
						<mat-icon>more_horiz</mat-icon>
					</button>
					<mat-menu #submenu="matMenu">
						<button mat-menu-item (click)="openQuiz(elmt)">Abrir Quiz</button>
						<button mat-menu-item (click)="detailQuiz(elmt)">Detalhes</button>
						<button mat-menu-item (click)="removeQuiz(elmt)">Remover</button>
					</mat-menu>
				</td>
		  	</ng-container>
	  
		  	<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</section>

	<section class="modal-container" *ngIf="showCreationModal">
		<div class="add-modal" id="mod1">
			<header>
				<mat-icon class="icon material-icons-outlined">quiz</mat-icon>
				<h1>Novo questionário a partir de especificações</h1>
				<mat-icon class="closing material-icons-outlined" (click)="cancelCreation()">close</mat-icon>
			</header>

			<div class="content">
				<form [formGroup]="formGroup" style="border-bottom: 1px solid #444;">					
					<div class="form-container">
						<!-- TITLE -->
						<mat-form-field>
							<mat-label>Título</mat-label>
							<input matInput autocomplete="off" formControlName="title">
						</mat-form-field>
						
						<!-- PATH -->
						<mat-form-field>
							<mat-label>Caminho</mat-label>
							<input matInput autocomplete="off" formControlName="path">
						</mat-form-field>

						<!-- CATEGORY -->
						<mat-form-field>
							<mat-label>Categoria</mat-label>
							<mat-select formControlName="vehicleCategory">
								<mat-option *ngFor="let category of categories" [value]="category.value">
									{{ category.text }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</form>

				<div class="specifications" [sortablejs]="specifications">
					<div class="spec" *ngFor="let spec of specifications; let index = index;">
						<mat-icon style="cursor: move;">reorder</mat-icon>
						<p style="cursor: move;">{{ spec.title }}</p>
						<mat-icon class="material-icons-outlined del" (click)="removeSpecFromCreation(index)">delete</mat-icon>
					</div>
				</div>

				<footer>
					<button type="button" class="cancel-btn" mat-stroked-button [disabled]="loading" (click)="cancelCreation()">
						<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
						<span>Cancelar</span>
					</button>
					<button class="save-btn" mat-flat-button (click)="createQuiz()" [class.spinner]="loading" [disabled]="loading">
						<span [class.opac]="loading">Salvar</span>
						<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
					</button>
				</footer>
			</div>
		</div>
	</section>

	<section class="modal-container" *ngIf="showDetailModal">
		<div class="add-modal" id="mod1">
			<header>
				<mat-icon class="icon material-icons-outlined">quiz</mat-icon>
				<h1>Detalhes de Questionário</h1>
				<mat-icon class="closing material-icons-outlined" (click)="cancelDetail()">close</mat-icon>
			</header>

			<div class="content">
				<form [formGroup]="formGroup" style="border-bottom: 1px solid #444;">					
					<div class="form-container">
						<!-- TITLE -->
						<mat-form-field>
							<mat-label>Título</mat-label>
							<input matInput autocomplete="off" formControlName="title">
						</mat-form-field>
						
						<!-- PATH -->
						<mat-form-field>
							<mat-label>Caminho</mat-label>
							<input matInput autocomplete="off" formControlName="path">
						</mat-form-field>

						<!-- CATEGORY -->
						<mat-form-field>
							<mat-label>Categoria</mat-label>
							<mat-select formControlName="vehicleCategory">
								<mat-option *ngFor="let category of categories" [value]="category.value">
									{{ category.text }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</form>

				<div class="specifications" [sortablejs]="specifications">
					<div class="spec" *ngFor="let spec of specifications; let index = index;">
						<mat-icon style="cursor: move;">reorder</mat-icon>
						<p style="cursor: move;">{{ spec.description }}</p>
						<mat-icon class="material-icons-outlined del" (click)="removeSpecFromCreation(index)">delete</mat-icon>
					</div>
				</div>

				<div style="display: flex;justify-content: flex-end;">
					<button type="button" mat-button (click)="addNewQuestion()">
						<mat-icon class="material-icons-outlined" style="color: #00adef;font-size: 20px;">add</mat-icon>
						<span style="color: #00adef;">Adicionar Questão</span>
					</button>
				</div>

				<footer>
					<button type="button" class="cancel-btn" mat-stroked-button [disabled]="loading" (click)="cancelDetail()">
						<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
						<span>Cancelar</span>
					</button>
					<button class="save-btn" mat-flat-button (click)="updateQuiz()" [class.spinner]="loading" [disabled]="loading">
						<span [class.opac]="loading">Salvar</span>
						<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
					</button>
				</footer>
			</div>
		</div>
	</section>

	<section class="modal-container" *ngIf="showPickQuestionModal">
		<div class="add-modal" id="mod1">
			<header>
				<mat-icon class="icon material-icons-outlined">quiz</mat-icon>
				<h1>Adição de Perguntas</h1>
				<mat-icon class="closing material-icons-outlined" (click)="closeNewQuestion()">close</mat-icon>
			</header>

			<div class="content">
				<div class="specifications" [sortablejs]="uniqueSpecs">
					<div class="spec" *ngFor="let spec of uniqueSpecs; let index = index;">
						<mat-icon style="cursor: move;">reorder</mat-icon>
						<p style="cursor: move;">{{ spec.description }}</p>
						<mat-icon class="material-icons-outlined del" (click)="removeSpecFromUnique(index)">delete</mat-icon>
					</div>
				</div>

				<footer>
					<button type="button" class="cancel-btn" mat-stroked-button [disabled]="loading" (click)="closeNewQuestion()">
						<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
						<span>Cancelar</span>
					</button>
					<button class="save-btn" mat-flat-button (click)="pickQuestions()" [class.spinner]="loading" [disabled]="loading">
						<span [class.opac]="loading">Adicionar</span>
						<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
					</button>
				</footer>
			</div>
		</div>
	</section>
</section>