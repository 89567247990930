<section id="menu">
	<img [src]="userImage && userImage != 'null' ? userImage : '../../assets/logo_merc.png'" alt="Avatar" />
	<br>
	<label class="subtitle-md" style="font-family: sans-serif;">{{ userName }}</label>
	<br>
	<span class="subtitle-sm" style="font-family: sans-serif;">{{ userEmail }}</span>

	<ul>
		<li *ngFor="let item of menu;let index = index;">
			<button mat-button (click)="goTo(index)">
				<mat-icon class="material-icons-outlined">{{ item.icon }}</mat-icon>
				<span class="subtitle-sm" style="font-family: sans-serif;">{{ item.title }}</span>
				<mat-icon class="material-icons-outlined">chevron_right</mat-icon>
			</button>
		</li>
	</ul>

	<button class="btn-logout" mat-button (click)="logout()">
		<mat-icon class="material-icons-outlined">logout</mat-icon>
		<span class="subtitle-sm" style="font-family: sans-serif;">Sair</span>
	</button>

	<div class="backdrop" (click)="close()" *ngIf="menuOpened"></div>
</section>