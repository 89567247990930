export class Financial {

	id: string
    tac: number
    installment: number
    interestRate: number
    note: string
    quotient: number
    createdAt: Date
    updatedAt: Date
    
	constructor(json?: any) {
        if (typeof json === 'string') {
            this.id = json
        } else if (json) {
            this.id = json.id
            this.tac = json.tac
            this.installment = json.installment
            this.interestRate = json.interestRate
            this.note = json.note
            this.quotient = json.quotient
            this.createdAt = json.createdAt
            this.updatedAt = json.updatedAt
        }
    }

}