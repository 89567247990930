import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { EventEmitterService } from '@app/_services/event-emitter.service';

@Component({
	selector: 'main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {

	showMessage: boolean

	constructor() { }

	ngOnInit(): void {
		this.showMessage = localStorage.getItem('user') ? true : false

		EventEmitterService.get('showMessage').subscribe((data: boolean) => {
			this.showMessage = data
		})
	}
	
}