import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { appRoutingModule } from './app.routing';
import { HomeComponent } from './_pages/home/home.component';
import { LoadingComponent } from './_common/loading/loading.component';
import { LoginComponent } from './_pages/login/login.component';
import { MaterialModule } from './material.module';
import { BtnComponent } from './_common/button/btn.component';
import { InptComponent } from './_common/input/inpt.component';
import { HeadComponent } from './_common/head/head.component';
import { MenuComponent } from './_common/menu/menu.component';
import { AlertComponent } from './_common/alert/alert.component';
import { SpecificationsComponent } from './_pages/specifications/specifications.component';
import { MainComponent } from './_pages/main/main.component';
import { CustomFilterPipe } from './_pipes/customfilter.pipe';
import { ExpansionPanelComponent } from './_common/expansion-panel/expansion-panel.component';
import { VehiclesComponent } from './_pages/vehicles/vehicles.component';
import { FinancialComponent } from './_pages/financial/financial.component';
import { UsersComponent } from './_pages/users/users.component';
import { ConfirmComponent } from './_common/confirm/confirm.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmService } from './_services/confirm.service';
import { NgxMaskModule } from 'ngx-mask'
import { QuizzesComponent } from './_pages/quizzes/quizzes.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { ExternalQuizComponent } from './_pages/external-quiz/external-quiz.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngVirtualKeyboardModule } from 'ang-virtual-keyboard';
import { PreOrdersComponent } from './_pages/pre-orders/pre-orders.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt');

@NgModule({
	declarations: [
		AppComponent,
		LoadingComponent,
		HomeComponent,
		LoginComponent,
		BtnComponent,
		InptComponent,
		HeadComponent,
		MenuComponent,
		AlertComponent,
		ConfirmComponent,
		MainComponent,
		SpecificationsComponent,
		CustomFilterPipe,
		ExpansionPanelComponent,
		VehiclesComponent,
		FinancialComponent,
		UsersComponent,
		QuizzesComponent,
		ExternalQuizComponent,
		PreOrdersComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		appRoutingModule,
		NgbModule,
		NgxMaskModule.forRoot(),
		SortablejsModule.forRoot({ animation: 150 }),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		}),
		AngVirtualKeyboardModule,
		MatNativeDateModule
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
		{ provide: LOCALE_ID, useValue: 'pt' },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
		ConfirmService
	],
	bootstrap: [AppComponent]
})
export class AppModule {}