import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlertConfig } from '@app/_common/alert/alert.model';
import { Quiz } from '@app/_models/quiz';
import { ConfirmService } from '@app/_services/confirm.service';
import { EventEmitterService } from '@app/_services/event-emitter.service';
import { QuizService } from '@app/_services/quiz.service';
import { SpecificationService } from '@app/_services/specification.service';
import * as $ from 'jquery';

@Component({
	selector: 'quizzes',
	templateUrl: './quizzes.component.html',
	styleUrls: ['./quizzes.component.scss'],
	encapsulation: ViewEncapsulation.None,
	host: { 'style': 'display: block; height: calc(100% - 50px);' }
})
export class QuizzesComponent implements OnInit {

	displayedColumns = ['title', 'options']
  	dataSource: any
	loading: boolean = false

	showCreationModal: boolean = false
	showDetailModal: boolean = false
	currentQuizEditing: string
	showPickQuestionModal: boolean = false
	uniqueSpecs: any = []

	formGroup: FormGroup
	rawSpecifications: any[]
	specifications: any[]

	categories = [ { value: 'Truck', text: 'Truck' }, { value: 'Furgao', text: 'Furgão' }, { value: 'Van', text: 'Van' } ]
	
	public constructor(
		private _router: Router,
		private _quizService: QuizService,
		private _confirmService: ConfirmService,
		private _specificationService: SpecificationService
	) {
		this.formGroup = new FormGroup({
			'title': new FormControl('', [Validators.required]),
			'path': new FormControl('', [Validators.required]),
			'vehicleCategory': new FormControl('', [Validators.required])
		})
	}

	ngOnInit(): void {
		EventEmitterService.get('showLoading').emit(true)
		this.getQuizzes()
		this.setupSpecifications()
	}

	goBack() {
		this._router.navigate(['/'])
	}

	getQuizzes() {
		this._quizService
			.list()
			.subscribe(response => {
				if (response.success) {
					let quizzes = response.data.map((x: any) => new Quiz(x))
					this.dataSource = new MatTableDataSource(quizzes)
				}

				EventEmitterService.get('showLoading').emit(false)
			})
	}

	setupSpecifications() {
		this._specificationService
			.list()
			.subscribe(response => {
				if (response.success) {
					this.rawSpecifications = response.data
					this.mapSpecifications()
				}
			})
	}

	private mapSpecifications() {
		this.specifications = [...this.rawSpecifications.sort((a, b) => {
			if (a.title > b.title) return 1
			if (a.title < b.title) return -1
			return 0
		}).map((value: any, index: number) => {
			return {
				id: value.id,
				title: value.title,
				description: value.question.description
			}
		})]
	}

	openCreationQuiz() {
		this.mapSpecifications()
		this.showCreationModal = true
	}

	cancelCreation() {
		this._confirmService
		.confirm('Fechar Criação', 'Tem certeza que deseja fechar a criação?')
		.then((confirmed) => {
			if (confirmed) {
					this.showCreationModal = false
					this.formGroup.reset('')
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	removeSpecFromCreation(index) {
		this.specifications.splice(index, 1)
	}

	openQuiz(quiz) {
		const url = this._router.serializeUrl(
			this._router.createUrlTree([`/external-quiz/${quiz.path}`])
		)
		
		window.open(`${window.location.origin}/#${url}`, '_blank')
	}
	
	detailQuiz(quiz) {
		this.showDetailModal = true
		this.currentQuizEditing = quiz.id
		this.specifications = []

		this._quizService
			.get(this.currentQuizEditing)
			.subscribe(response => {
				if (response.success) {			
					let qd = response.data
					this.formGroup.get('title').setValue(qd.title)
					this.formGroup.get('path').setValue(qd.path)
					this.formGroup.get('vehicleCategory').setValue(qd.vehicleCategory)
			
					if (qd.specifications)
						this.specifications = qd.specifications.sort((a, b) => {
							if (a.index > b.index) return 1
							if (a.index < b.index) return -1
							return 0
						})

					return
				}
				
				this.showDetailModal = false
			}, error => this.showDetailModal = false)
	}

	cancelDetail() {
		this._confirmService
		.confirm('Fechar Edição', 'Tem certeza que deseja fechar a edição?')
		.then((confirmed) => {
			if (confirmed) {
					this.showDetailModal = false
					this.formGroup.reset('')
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}
	
	removeQuiz(quiz) {
		this._confirmService
			.confirm('Atenção!', 'Tem certeza que deseja remover o questionário?')
			.then((confirmed) => {
				if (confirmed) {
					let index = this.dataSource.data.findIndex((x: Quiz) => x == quiz)
					if (index > -1) {
						this._quizService
							.delete(quiz.id)
							.subscribe(response => {
								if (!response.success) {
									EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
									return
								}
			
								this.dataSource.data.splice(index, 1)
								this.dataSource._updateChangeSubscription()
							})
					}
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}
	
	createQuiz() {
		if (!this.formGroup.valid) {
			EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Atenção', 'Existem campos de informação inválidos'))
			this.formGroup.markAllAsTouched()
			return
		}
		
		this.loading = true
		var data = this.formGroup.value

		data.specifications = this.specifications.map((value: any, index: number) => {
			return {
				...value,
				index: index
			}
		})

		this._quizService
			.post(data)
			.subscribe(response => {
				if (!response.success) {
					EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
					this.loading = false
					return
				}

				this.dataSource.data.push(new Quiz(response.data))
				this.dataSource._updateChangeSubscription()
				this.showCreationModal = false
				this.formGroup.reset('')
				this.loading = false
			})
	}

	updateQuiz() {
		if (!this.formGroup.valid) {
			EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Atenção', 'Existem campos de informação inválidos'))
			this.formGroup.markAllAsTouched()
			return
		}

		this.loading = true

		let putObj = {
			id: this.currentQuizEditing,
			...this.formGroup.value,
			specifications: this.specifications.map((value, index) => {
				value.index = index
				return value
			})
		}

		this._quizService
			.put(putObj)
			.subscribe(response => {
				if (!response.success) {
					EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
					this.loading = false
					return
				}

				let index = this.dataSource.data.findIndex((x: Quiz) => x.id == this.currentQuizEditing)

				if (index > -1) {
					this.dataSource.data[index] = new Quiz(response.data)
					this.dataSource._updateChangeSubscription()
				}

				this.showDetailModal = false
				this.currentQuizEditing = null
				this.formGroup.reset('')
				this.loading = false
			},
			error => {
				console.error(error)
				this.loading = false
			})
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	addNewQuestion() {		
		this.uniqueSpecs = []
		
		this.rawSpecifications.forEach(x => {
			if (this.specifications.findIndex(y => y.id == x.id) == -1) {
				this.uniqueSpecs.push({
					id: x.id,
					description: x.question.description
				})
			}
		})
		
		this.showPickQuestionModal = true
	}

	closeNewQuestion() {
		this.showPickQuestionModal = false
	}

	removeSpecFromUnique(index) {
		this.uniqueSpecs.splice(index, 1)
	}

	pickQuestions() {
		let lastIndex = this.specifications[this.specifications.length - 1].index
		this.uniqueSpecs.forEach(x => {
			lastIndex++
			x.index = lastIndex
		})

		this.specifications = [...this.specifications, ...this.uniqueSpecs]
		this.showPickQuestionModal = false
	}

}