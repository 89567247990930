import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'head',
	templateUrl: './head.component.html',
	styleUrls: ['./head.component.scss'],
	host: { 'style': 'height: 50px;' }
})
export class HeadComponent implements OnInit {
	
	menuOpened: boolean
	userName: string
	@Input() avatar: string
	userEmail: string

	constructor() {
		this.menuOpened = false
	}

	ngOnInit(): void {
		this.avatar = localStorage.getItem('userImage')
	}

	openMenu() {
		this.menuOpened = true
		this.userName = localStorage.getItem('userName')
		this.avatar = localStorage.getItem('userImage')
		this.userEmail = localStorage.getItem('user')
	}
	
	closedMenu() {
		this.menuOpened = false
	}
}