<header>
	<div class="items">
		<button mat-icon-button (click)="openMenu()">
			<mat-icon>menu</mat-icon>
		</button>
		<img class="logo" src="/assets/dlogo_medium.png" alt="DVA Logo">
		<img class="avatar" [src]="avatar && avatar != 'null' ? avatar : '../../assets/logo_merc.png'" alt="Avatar">
	</div>

	<menu
		[ngClass]="{ 'closed': !menuOpened }"
		[menuOpened]="menuOpened"
		[userName]="userName"
		[userImage]="avatar"
		[userEmail]="userEmail"
		(callback)="closedMenu()">
	</menu>
</header>