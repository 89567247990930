import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs/internal/Observable";
import { Quiz } from "@app/_models/quiz";
import { PreOrder } from "@app/_models/preorder";

@Injectable({ providedIn: 'root' })
export class QuizService {

	constructor(private http: HttpClient) {}

	list(): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/quiz`)
	}

	get(id: string): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/quiz/${id}`)
	}

	post(quiz: Quiz): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/quiz`, quiz)
	}

	put(quiz: Quiz): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/quiz`, quiz)
	}

	delete(id: string): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/quiz/${id}`)
	}

	getQuiz(path: string, category: string): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/external/quiz/${path}?vehicleCategory=${category}`)
	}

	getNextQuestion(id: string, queryString: string): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/external/quiz/reply/${id}${queryString}`)
	}

	saveExternalPreOrder(preOrder: PreOrder): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/external/preorder`, preOrder)
	}

	getStates(): any[] {
        return [
			{
				state: 'Santa Catarina', cities: [
					'Águas Mornas',
					'Angelina',
					'Anitápolis',
					'Antônio Carlos',
					'Biguaçu',
					'Bombinhas',
					'Canelinha',
					'Florianópolis',
					'Garopaba',
					'Governador Celso Ramos',
					'Imbituba',
					'Major Gercino',
					'Nova Trento',
					'Palhoça',
					'Paulo Lopes',
					'Porto Belo',
					'Rancho Queimado',
					'Santo Amaro da Imperatriz',
					'São Bonifácio',
					'São João Batista',
					'São José',
					'São Pedro de Alcantara',
					'Tijucas'
				]
			},
			{ state: 'Rio Grande do Sul', cities: ['Porto Alegre', 'Novo Hamburgo', 'Pelotas'] }]
    }
}