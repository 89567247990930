import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
	host: { 'style': 'display: block;height: 100%;position: fixed;z-index: 1000;width: 100%;' }
})
export class LoadingComponent implements OnInit {
	
	constructor() {}

	ngOnInit(): void {
		// Init
	}
	
}