<section id="home">
	<div
		class="background"
		[ngClass]="{'login-open': !isLogged, 'back-img': showBackImage}">
	</div>
	
	<head *ngIf="isLogged" [avatar]="avatar"></head>
	
	<login
		(loginCallback)="onLogin($event)"
		[ngClass]="{ 'login-close' : isLogged }"
		[style.display]="hideLogin">
	</login>
	
	<alert
		[icon]="alertConfig.icon"
		[type]="alertConfig.type"
		[title]="alertConfig.title"
		[text]="alertConfig.content"
		[reload]="alertConfig.reload"
		btnText="Entendi"
		*ngIf="alertConfig">
	</alert>

	<loading *ngIf="showLoading"></loading>

	<router-outlet></router-outlet>
</section>