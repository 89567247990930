export class Vehicle {
	id:	string
	createdAt: Date
	updatedAt: Date
	image: string
	model: string
	version: string
	category: string
	price: number
	entryPrice: number
	icms: string
	specifications:  VehicleSpecification[]

	constructor(json?: any) {
        if (typeof json === 'string') {
            this.id = json
        } else if (json) {
            this.id = json.id
			this.createdAt = json.createdAt
			this.updatedAt = json.updatedAt
			this.image = json.image
			this.model = json.model
			this.version = json.version
			this.category = json.category
			this.price = json.price
			this.entryPrice = json.entryPrice
			this.icms = json.icms

			if (json.specifications && json.specifications.length)
				this.specifications = json.specifications.map((x: any) => new VehicleSpecification(x))
        }
    }
}

export class VehicleSpecification {
	specificationId: string
	title: string
	value: string

	constructor(json?: any) {
        if (json) {
            this.specificationId = json.specificationId
            this.title = json.title
            this.value = json.value
        }
    }
}