import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventEmitterService } from '@app/_services/event-emitter.service';

@Component({
	selector: 'alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

	@Input() type: string
	@Input() icon: string
	@Input() title: string
	@Input() text: string
	@Input() btnText: string
	@Input() reload: boolean

	constructor() {}

	ngOnInit(): void {}

	closeClick(): void {
		EventEmitterService.get('closeAlert').emit({ reload: this.reload })
	}
	
}