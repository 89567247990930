import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs/internal/Observable";
import { User } from "@app/_models/user";

@Injectable({ providedIn: 'root' })
export class UserService {

	constructor(private http: HttpClient) {}

	list(): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/user`)
	}

	get(id: string): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/user/${id}`)
	}

	post(user: User): Observable<any> {
		user.username = user.email
		return this.http.post<any>(`${environment.apiUrl}/user`, user)
	}

	put(user: User): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/user`, user)
	}

	delete(id: string): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/user/${id}`)
	}

}