<section id="specifications">
	<header class="page-header">
		<mat-icon class="material-icons-outlined back" (click)="goBack()" style="cursor: pointer;">keyboard_arrow_left</mat-icon>
		<div class="title">
			<mat-icon class="material-icons-outlined">fact_check</mat-icon>
			<span>Especificações</span>
		</div>
		<div></div>
	</header>

	<section class="tools">
		<mat-form-field>
			<mat-label>Pesquisar</mat-label>
			<input matInput placeholder="Pesquisar" autocomplete="off" [formControl]="filter">
		</mat-form-field>
		<btn icon="add" text="Novo" color="blue" (callback)="openCreation()"></btn>
	</section>
	
	<section class="panels">
		<mat-accordion>
			<expansion-panel *ngFor="let spec of specificationsFormGroups | customFilter: { field: 'title' , filter: filter.value }; let index = index;" [InnerFormGroup]="spec" (callback)="deleteSpec($event)" (update)="updateSpec($event)" [loading]="loading"></expansion-panel>
		</mat-accordion>
	</section>

	<section class="modal-container" *ngIf="showCreationModal">
		<div class="add-modal" [formGroup]="addFormGroup">
			<header>
				 <mat-icon class="icon material-icons-outlined">fact_check</mat-icon>
				 <h1>Nova especificação</h1>
				 <mat-icon class="closing material-icons-outlined" (click)="cancelCreation()">close</mat-icon>
			</header>
	
			<div class="content">
				<!-- NAME -->
				<mat-form-field style="width: 50%;">
					<mat-label>Digite aqui o nome da especificação</mat-label>
					<input matInput autocomplete="off" formControlName="name">
				</mat-form-field>
				
				<div class="form-container">
					<!-- QUESTION -->
					<mat-form-field>
						<mat-label>Descreva qual o questionamento</mat-label>
						<input matInput autocomplete="off" formControlName="question">
					</mat-form-field>
		
					<!-- TYPE -->
					<mat-form-field>
						<mat-label>Tipo</mat-label>
						<mat-select formControlName="type">
							<mat-option *ngFor="let type of types" [value]="type.value">
								{{ type.text }}
							</mat-option>
						</mat-select>
					</mat-form-field>
	
					<!-- OPTIONS -->
					<mat-form-field>
						<mat-label>Opções</mat-label>
						<mat-chip-list #chipList aria-label="Seleção de opções" formControlName="options">
							<mat-chip *ngFor="let option of addFormGroup.get('options').value" [selectable]="false" (removed)="removeChipForNewSpec(option)">
								{{ option }} <mat-icon matChipRemove>close</mat-icon>
							</mat-chip>
							<input
								placeholder="Nova Opção"
								class="ng-pristine ng-invalid ng-invalid-required ng-touched"
								[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
								[matChipInputAddOnBlur]="true"
								[matChipInputFor]="chipList"
								(matChipInputTokenEnd)="addChipForNewSpec($event)">
						</mat-chip-list>
					</mat-form-field>
				</div>
			</div>
	
			<footer>
				<button type="button" class="cancel-btn" mat-stroked-button (click)="cancelCreation()" [disabled]="loading">
					<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
					<span>Cancelar</span>
				</button>
				<button class="save-btn" mat-flat-button (click)="createSpecification()" [class.spinner]="loading" [disabled]="loading">
					<span [class.opac]="loading">Finalizar</span>
					<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
				</button>
			</footer>
		</div>
	</section>
</section>