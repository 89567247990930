import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'btn',
	templateUrl: './btn.component.html',
	styleUrls: ['./btn.component.scss']
})
export class BtnComponent {

	@Input() icon: string
	@Input() text: string
	@Input() color: string
	@Input() small: boolean
	@Input() noIcon: boolean
	@Input() loading: boolean
	
	@Output() callback: EventEmitter<any>

	constructor() {
		this.callback = new EventEmitter();
	}

	setBtnClasses(): string[] {
		let classes: string[] = []

		classes.push(this.color)

		if (this.text)
			if (this.noIcon)
				classes.push('paddNoIcon')
			else
				classes.push(this.small != undefined ? 'small' : 'paddText')
		else
			classes.push('paddIcon')

		return classes
	}

	btnClick(): void {
		this.callback.emit([])
	}
	
}