import { ChangeDetectorRef } from '@angular/core';
import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertConfig } from '@app/_common/alert/alert.model';
import { EventEmitterService } from '@app/_services/event-emitter.service';

@Component({
	selector: 'home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
	
	inititalLoading = true
	isLogged: boolean
	hideLogin: string
	showLoading: boolean
	showBackImage: boolean
	avatar: string

	alertConfig: AlertConfig

	constructor(private router: Router, private _cdRef: ChangeDetectorRef) {
		this.isLogged = false
		this.hideLogin = 'block'
		this.showLoading = true

		this.showBackImage = this.router.url == '/main'
		
		EventEmitterService.get('showAlert').subscribe((data: any) => {
			this.alertConfig = new AlertConfig(data.icon, data.type, data.title, data.content, data.reload)
		})

		EventEmitterService.get('closeAlert').subscribe((data: any) => {
			this.alertConfig = undefined
			if (data.reload) location.reload()
		})

		EventEmitterService.get('showLogin').subscribe((data: any) => {
			this.isLogged = !data
			this.hideLogin = 'block'
		})

		EventEmitterService.get('showLoading').subscribe((data: boolean) => {
			if (!this.inititalLoading)
				this.showLoading = data
		})
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.showLoading = false
			this.inititalLoading = false
			
			if (localStorage.getItem('user'))
				this.onLogin(localStorage.getItem('user'))
			else
				this.router.navigate(['/login'])
		}, 4000);

		this.detectRouteChange()
	}

	ngAfterViewChecked() {
		this._cdRef.detectChanges()
	}

	ngOnDestroy() {
		EventEmitterService.get('showAlert').unsubscribe()
		EventEmitterService.get('closeAlert').unsubscribe()
		EventEmitterService.get('showLogin').unsubscribe()
		EventEmitterService.get('showLoading').unsubscribe()
	}

	onLogin(image: string) {
		this.isLogged = true
		this.avatar = image

		setTimeout(() => {
			this.hideLogin = 'none'
		}, 300);
	}

	private detectRouteChange() {
		this.router.events.subscribe((val) => {
			if (val instanceof NavigationEnd)
				this.showBackImage = val.urlAfterRedirects == '/main'
		})
	}
	
}