import { Vehicle } from "./vehicle"

export class PreOrder {
	  
	id: string
	customerId: string
	customer: PreOrderCustomer
	state: string
	city: string
	vehicleId: string
	vehicle: Vehicle
	status: number // Abandoned = 1, Waiting = 2, InContact = 3, Rejected = 4, Closed = 5
	createdAt: Date
	updatedAt: Date
    
	constructor(json?: any) {
		this.id = json.id
		this.customerId = json.customerId
		this.state = json.state
		this.city = json.city
		this.vehicleId = json.vehicleId
		this.status = json.status
		this.createdAt = json.createdAt
		this.updatedAt = json.updatedAt
		
		if (json.hasOwnProperty('customer') && json.customer)
			this.customer = new PreOrderCustomer(json.customer)
		
		if (json.hasOwnProperty('vehicle') && json.vehicle)
			this.vehicle = new Vehicle(json.vehicle)
    }

}

export class PreOrderCustomer {

	id: string
	name: string
	phone: string
	email: string
	createdAt: Date
	updatedAt: Date

	constructor(json?: any) {
		this.id = json.id
		this.name = json.name
		this.phone = json.phone
		this.email = json.email
		this.createdAt = json.createdAt
		this.updatedAt = json.updatedAt
	}
}