import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { SpecificationService } from '@app/_services/specification.service';
import { Specification } from '@app/_models/specification';
import { EventEmitterService } from '@app/_services/event-emitter.service';
import { AlertConfig } from '@app/_common/alert/alert.model';
import { ConfirmService } from '@app/_services/confirm.service';

@Component({
	selector: 'specifications',
	templateUrl: './specifications.component.html',
	styleUrls: ['./specifications.component.scss'],
	encapsulation: ViewEncapsulation.None,
	host: { 'style': 'display: block; height: calc(100% - 50px);' }
})
export class SpecificationsComponent implements OnInit {

	loading: boolean = false
	specifications: Specification[]
	specificationsFormGroups: FormGroup[]
	filter: FormControl
	types = [{ value: 'radio', text: 'Única Resposta' }, { value: 'checkbox', text: 'Múltiplas Respostas' }]

	showCreationModal: boolean = false
	addFormGroup: FormGroup

	readonly separatorKeysCodes = [ENTER, COMMA] as const;
	
	constructor(
		private _router: Router,
		private _specificationService: SpecificationService,
		private _confirmService: ConfirmService) {

		this.addFormGroup = new FormGroup({
			'name': new FormControl('', [Validators.required]),
			'question': new FormControl('', [Validators.required]),
			'type': new FormControl('', [Validators.required]),
			'options': new FormControl([], [])
		})
		this.specificationsFormGroups = []
	}

	ngOnInit(): void {
		EventEmitterService.get('showLoading').emit(true)
		this.getSpecificationData()
		this.filter = new FormControl('', [])
	}

	private getSpecificationData() {
		this._specificationService
			.list()
			.subscribe(response => {
				if (response.success) {
					this.specifications = response.data.map((x: any) => new Specification(x))
					this.setupFormGroups()
				}

				EventEmitterService.get('showLoading').emit(false)
			})
	}

	private setupFormGroups() {
		this.specificationsFormGroups = this.specifications.map(x => {
			return new FormGroup({
				'id': new FormControl(x.id, []),
				'title': new FormControl(x.title, [Validators.required]),
				'question': new FormControl(x.question.description, [Validators.required]),
				'selectedType': new FormControl(x.question.type, [Validators.required]),
				'options': new FormControl(x.question.options && x.question.options.length ? x.question.options : [], [])
			})
		})
	}

	addChipForNewSpec(event: MatChipInputEvent): void {
		const value = (event.value || '').trim()
		if (value) this.addFormGroup.get('options').value.push(value)
		event.chipInput!.clear()
	  }
	
	removeChipForNewSpec(option: string): void {
		let auxOpt = this.addFormGroup.get('options').value
		auxOpt.splice(auxOpt.indexOf(option), 1)
	}

	openCreation() {
		this.showCreationModal = true
	}

	cancelCreation() {
		this._confirmService
			.confirm('Fechar Criação', 'Tem certeza que deseja fechar a criação?')
			.then((confirmed) => {
				if (confirmed) {
					this.showCreationModal = false
					this.addFormGroup.reset('')
					this.addFormGroup.get('options').setValue([])
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	createSpecification() {
		this.addFormGroup.markAllAsTouched()
		
		if (!this.addFormGroup.get('options').value || this.addFormGroup.get('options').value.length == 0)
			this.addFormGroup.get('options').setErrors({ required: true })
		else
			this.addFormGroup.get('options').setErrors(null)

		if (this.addFormGroup.valid) {
			this.loading = true

			let spec = new Specification({
				title: this.addFormGroup.value.name,
				question: {
					description: this.addFormGroup.value.question,
					type: this.addFormGroup.value.type,
					options: this.addFormGroup.value.options
				}
			})
			
			this._specificationService
				.post(spec)
				.subscribe(response => {
					if (!response.success) {
						EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
						return
					}

					this.specifications.push(new Specification(response.data))
					this.specificationsFormGroups.push(new FormGroup({
						'id': new FormControl(response.data.id, []),
						'title': new FormControl(response.data.title, [Validators.required]),
						'question': new FormControl(response.data.question.description, [Validators.required]),
						'selectedType': new FormControl(response.data.question.type.toLowerCase(), [Validators.required]),
						'options': new FormControl(response.data.question.options && response.data.question.options.length ? response.data.question.options : [], [])
					}))
					this.showCreationModal = false
					this.addFormGroup.reset('')
					this.addFormGroup.get('options').setValue([])
					this.loading = false
				},
				error => {
					this.loading = false
					console.error(error)
				})
		}
	}

	deleteSpec(data: FormGroup) {
		this._confirmService
			.confirm('Atenção!', 'Tem certeza que deseja remover a especificação?')
			.then((confirmed) => {
				if (confirmed) {
					let index = this.specificationsFormGroups.findIndex(x => x == data)
			
					if (index > -1) {
						let specId = this.specificationsFormGroups[index].value.id
						
						this._specificationService
							.delete(specId)
							.subscribe(response => {
								if (!response) {
									EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', 'Não foi possível remover a especificação'))
									return
								}
			
								this.specifications.splice(this.specifications.findIndex(x => x.id == specId), 1)
								this.specificationsFormGroups.splice(index, 1)
							})
					}
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	updateSpec(data: FormGroup) {
		let currentSpec = this.specifications.find(x => x.id == data.value.id)

		if (currentSpec) {
			this.loading = true
			currentSpec.question.description = data.value.question
			currentSpec.question.options = data.value.options
			currentSpec.question.type = data.value.selectedType.toLowerCase()
	
			this._specificationService
				.put(currentSpec)
				.subscribe(response => {
					if (!response.success) {
						EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', 'Não foi possível atualizar a especificação'))
						return
					}
					
					EventEmitterService.get('showAlert').emit(new AlertConfig('check', 'info', 'Sucesso', 'Especificação atualizada'))
					this.loading = false
				},
				error => {
					this.loading = false
					console.error(error)
				})
		} else {
			EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', 'Não foi encontrada uma especificação'))
		}
	}

	goBack() {
		this._router.navigate(['/'])
	}
}