import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { VehicleService } from '@app/_services/vehicle.service';
import { Vehicle } from '@app/_models/vehicle';
import { SpecificationService } from '@app/_services/specification.service';
import { ImageService } from '@app/_services/image.service';
import { EventEmitterService } from '@app/_services/event-emitter.service';
import { AlertConfig } from '@app/_common/alert/alert.model';
import { ConfirmService } from '@app/_services/confirm.service';

@Component({
	selector: 'vehicles',
	templateUrl: './vehicles.component.html',
	styleUrls: ['./vehicles.component.scss'],
	encapsulation: ViewEncapsulation.None,
	host: { 'style': 'display: block; height: calc(100% - 50px);' }
})
export class VehiclesComponent implements OnInit {

	displayedColumns = ['model', 'version', 'category', 'options']
  	dataSource: any
	categories = [ { value: 'truck', text: 'Truck' }, { value: 'furgao', text: 'Furgão' }, { value: 'van', text: 'Van' } ]
	showCreationModal: boolean = false
	showDetail: boolean = false

	currentVehicle: Vehicle
	
	firstFG: FormGroup
	secondFG: FormGroup
	thirdFG: FormGroup

	previewImage: string
	file: File

	specifications: any[]
	types = [{ value: 0, text: 'Única Resposta' }, { value: 1, text: 'Múltiplas Respostas' }]
	readonly separatorKeysCodes = [ENTER, COMMA] as const;

	loading: boolean = false
	
	constructor(
		private _router: Router,
		private _vehicleService: VehicleService,
		private _specificationService: SpecificationService,
		private _imageService: ImageService,
		private _confirmService: ConfirmService) {

		this.firstFG = new FormGroup({
			'image': new FormControl('', []),
			'model': new FormControl('', [Validators.required]),
			'version': new FormControl('', [Validators.required]),
			'category': new FormControl('', [Validators.required])
		})

		this.secondFG = new FormGroup({
			'formArray': new FormArray([], [Validators.required])
		})

		this.thirdFG = new FormGroup({
			'price': new FormControl('', [Validators.required]),
			'entryPrice': new FormControl('', []),
			'icms': new FormControl('', [Validators.required])
		})
	}

	ngOnInit(): void {
		EventEmitterService.get('showLoading').emit(true)
		this.getVehicleData()
		this.setupSpecifications()
	}

	get formArray() {
		return this.secondFG.get('formArray') as FormArray
	}

	getVehicleData() {
		this._vehicleService
			.list()
			.subscribe(response => {
				if (response.success) {
					let vehicles = response.data.map((x: any) => new Vehicle(x))
					this.dataSource = new MatTableDataSource(vehicles)
				}

				EventEmitterService.get('showLoading').emit(false)
			})
	}

	setupSpecifications() {
		this._specificationService
			.list()
			.subscribe(response => {
				if (response.success) {
					this.specifications = response.data
				}
			})
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openCreation() {
		this.secondFG = new FormGroup({
			'formArray': new FormArray([], [Validators.required])
		})

		this.specifications.forEach((x: any) => {
			this.formArray.push(new FormGroup({
				'title': new FormControl(x.title, [Validators.required]),
				'options': new FormControl(x.question.options, []),
				'specificationId': new FormControl(x.id, [Validators.required]),
				'value': new FormControl('', [Validators.required])
			}))
		})

		this.showCreationModal = true
	}

	addNewSpec() {
		this.formArray.push(new FormGroup({
			'title': new FormControl('', [Validators.required]),
			'options': new FormControl([], []),
			'specificationId': new FormControl('000', [Validators.required]),
			'value': new FormControl('', [Validators.required])
		}))
	}

	markTouched() {
		this.formArray.controls.forEach(control => control.get('value').markAsDirty())
	}

	markFirstDirty() {
		this.firstFG.get('image').markAsDirty()
	}

	cancelCreation() {
		this._confirmService
			.confirm('Fechar Criação', 'Tem certeza que deseja fechar a criação?')
			.then((confirmed) => {
				if (confirmed) {
					this.showCreationModal = false
					this.firstFG.reset('')
					this.secondFG.reset('')
					this.thirdFG.reset('')
					this.previewImage = null
					this.file = null
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	createVehicle() {
		this.loading = true

		if (this.file) {
			this._imageService
				.post(this.file)
				.subscribe(response => {
					if (!response.success) {
						EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
						this.loading = false
						return
					}
	
					this.postVehicle(response.data)
				},
				error => {
					this.loading = false
					console.error(error)
				})
		} else {
			this.postVehicle(null)
		}
	}
		
	private postVehicle(imageLink: string) {
		let vehicle = new Vehicle()

		vehicle.model = this.firstFG.get('model').value
		vehicle.category = this.firstFG.get('category').value.toLowerCase()
		vehicle.version = this.firstFG.get('version').value
		vehicle.image = imageLink
		vehicle.price = this.thirdFG.get('price').value
		vehicle.entryPrice = this.thirdFG.get('entryPrice').value
		vehicle.icms = this.thirdFG.get('icms').value
		
		vehicle.specifications = this.formArray.value.map((spec: any) => {
			let mapSpec: any = {
				title: spec.title,
				value: spec.value
			}

			if (spec.specificationId != "000")
				mapSpec.specificationId = spec.specificationId

			return mapSpec
		})

		this._vehicleService
			.post(vehicle)
			.subscribe(response => {
				if (!response.success) {
					EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
					this.loading = false
					return
				}

				this.dataSource.data.push(new Vehicle(response.data))
				this.dataSource._updateChangeSubscription()
				this.showCreationModal = false
				this.firstFG.reset('')
				this.secondFG.reset('')
				this.thirdFG.reset('')
				this.previewImage = null
				this.file = null
				this.loading = false
			},
			error => {
				this.loading = false
				console.error(error)
			})
	
	}

	goBack() {
		this._router.navigate(['/'])
	}

	imagesPreview(event) {
        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();

            reader.onload = (_event: any) => {
				if (event.srcElement.files[0]) {
					this.previewImage = _event.target.result
					this.file = event.srcElement.files[0]
					this.firstFG.get('image').setValue(event.srcElement.files[0].name)
				}
            }

            reader.readAsDataURL(event.target.files[0]);
        }
    }

	removeSpecification(index: number) {
		(this.secondFG.get('formArray') as FormArray).removeAt(index)
	}

	showVehicle(elmnt: any) {		
		this.secondFG = new FormGroup({
			'formArray': new FormArray([], [Validators.required])
		})

		elmnt.specifications.forEach((x: any) => {
			this.formArray.push(new FormGroup({
				'title': new FormControl(x.title, [Validators.required]),
				'specificationId': new FormControl(x.specificationId ? x.specificationId : '000', [Validators.required]),
				'value': new FormControl(x.value, [Validators.required])
			}))
		})
		
		this.showDetail = true
		this.currentVehicle = this.dataSource.data.find((x: Vehicle) => x.id == elmnt.id)
		this.firstFG.get('image').setValue(this.currentVehicle.image)
		this.previewImage = this.currentVehicle.image
		this.firstFG.get('model').setValue(this.currentVehicle.model)
		this.firstFG.get('version').setValue(this.currentVehicle.version)
		this.firstFG.get('category').setValue(this.currentVehicle.category.toLowerCase())
		this.thirdFG.get('price').setValue(this.currentVehicle.price.toString().replace('.', ','))
		this.thirdFG.get('entryPrice').setValue(this.currentVehicle.entryPrice.toString().replace('.', ','))
		this.thirdFG.get('icms').setValue(this.currentVehicle.icms.toString().replace('.', ','))
	}

	cancelDetail() {
		this._confirmService
			.confirm('Fechar Edição', 'Tem certeza que deseja fechar a edição?')
			.then((confirmed) => {
				if (confirmed) {
					this.showDetail = false
					this.currentVehicle = null
					this.firstFG.reset('')
					this.secondFG.reset('')
					this.thirdFG.reset('')
					this.previewImage = null
					this.file = null
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	async updateVehicle() {
		this.firstFG.markAllAsTouched()
		this.thirdFG.markAllAsTouched()

		if (this.firstFG.valid && this.thirdFG.valid && this.formArray.valid) {
			this.loading = true

			if (this.file) {
				let imageResponse = await this._imageService.post(this.file).toPromise()
				if (imageResponse.success)
					this.currentVehicle.image = imageResponse.data
			} else {
				this.currentVehicle.image = this.firstFG.get('image').value
			}

			this.currentVehicle.model = this.firstFG.get('model').value
			this.currentVehicle.category = this.firstFG.get('category').value.toLowerCase()
			this.currentVehicle.version = this.firstFG.get('version').value
			this.currentVehicle.price = this.thirdFG.get('price').value
			this.currentVehicle.entryPrice = this.thirdFG.get('entryPrice').value
			this.currentVehicle.icms = this.thirdFG.get('icms').value
			
			this.currentVehicle.specifications = this.formArray.value.map((spec: any) => {
				let mapSpec: any = {
					title: spec.title,
					value: spec.value
				}

				if (spec.specificationId != "000" && spec.specificationId != null)
					mapSpec.specificationId = spec.specificationId

				return mapSpec
			})

			this._vehicleService
				.put(this.currentVehicle)
				.subscribe(response => {
					if (!response.success) {
						EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', 'Não foi possível atualizar o veículo'))
						this.loading = false
						return
					}

					let index = this.dataSource.data.findIndex((x: Vehicle) => x.id == this.currentVehicle.id)

					if (index > -1) {
						this.dataSource.data[index] = new Vehicle(response.data)
						this.dataSource._updateChangeSubscription()
					}

					this.showDetail = false
					this.currentVehicle = null
					this.firstFG.reset('')
					this.secondFG.reset('')
					this.thirdFG.reset('')
					this.previewImage = null
					this.file = null
					this.loading = false
				},
				error => {
					this.loading = false
					console.error(error)
				})			
		} else {
			EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Atenção', 'Existem campos inválidos. Por favor, verifique o cadastro novamente antes de salvar.'))
		}
	}

	removeVehicle(elmnt: any) {
		this._confirmService
			.confirm('Atenção!', 'Tem certeza que deseja remover o veículo?')
			.then((confirmed) => {
				if (confirmed) {
					let index = this.dataSource.data.findIndex((x: Vehicle) => x == elmnt)
					if (index > -1) {
						this._vehicleService
							.delete(elmnt.id)
							.subscribe(response => {
								if (!response.success) {
									EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
									return
								}
			
								this.dataSource.data.splice(index, 1)
								this.dataSource._updateChangeSubscription()
							})
					}
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	getCategoryLabel(value: string): string {
		if (!value) return value
		let category = this.categories.find(x => x.value.toLowerCase() == value.toLowerCase())
		return category ? category.text : value
	}

	removeImage() {
		this.firstFG.get('image').setValue(null)
		this.previewImage = null
		this.file = null
	}
}