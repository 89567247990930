import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs/internal/Observable";

@Injectable({ providedIn: 'root' })
export class ImageService {

	constructor(private http: HttpClient) {}

	post(image: File): Observable<any> {
		const httpOptions = { headers: new HttpHeaders({ 'Content-Disposition': 'multipart/form-data' }) }
		let formData = new FormData()
	
		formData.append('image', image)

		return this.http.post<any>(`${environment.apiUrl}/image`, formData, httpOptions)
	}

}