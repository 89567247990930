import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs/internal/Observable";
import { Financial } from "@app/_models/financial";

@Injectable({ providedIn: 'root' })
export class FinancialService {

	constructor(private http: HttpClient) {}

	list(): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/financial`)
	}

	get(id: string): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/financial/${id}`)
	}

	post(financial: Financial): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/financial`, financial)
	}

	put(financial: Financial): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/financial`, financial)
	}

	delete(id: string): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/financial/${id}`)
	}

}