import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs/internal/Observable";
import { Specification } from "@app/_models/specification";

@Injectable({ providedIn: 'root' })
export class SpecificationService {

	constructor(private http: HttpClient) {}

	list(): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/specification`)
	}

	get(id: string): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/specification/${id}`)
	}

	post(specification: Specification): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/specification`, specification)
	}

	put(specification: Specification): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/specification`, specification)
	}

	delete(id: string): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/specification/${id}`)
	}

}