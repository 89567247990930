import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from "rxjs/internal/Observable";
import { Vehicle } from "@app/_models/vehicle";

@Injectable({ providedIn: 'root' })
export class VehicleService {

	constructor(private http: HttpClient) {}

	list(): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/vehicle`)
	}

	get(id: string): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/vehicle/${id}`)
	}

	post(vehicle: Vehicle): Observable<any> {
		return this.http.post<any>(`${environment.apiUrl}/vehicle`, vehicle)
	}

	put(vehicle: Vehicle): Observable<any> {
		return this.http.put<any>(`${environment.apiUrl}/vehicle`, vehicle)
	}

	delete(id: string): Observable<any> {
		return this.http.delete<any>(`${environment.apiUrl}/vehicle/${id}`)
	}

}