import { NgModule } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatStepperModule } from '@angular/material/stepper';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";

@NgModule({
	exports: [
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatExpansionModule,
		MatSelectModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatTableModule,
		MatStepperModule,
		MatMenuModule,
		MatTabsModule,
		MatTooltipModule,
		MatCheckboxModule,
		MatRadioModule,
		MatDatepickerModule
	],
	imports: [
		MatFormFieldModule,
		MatInputModule,
		MatIconModule,
		MatButtonModule,
		MatExpansionModule,
		MatSelectModule,
		MatChipsModule,
		MatAutocompleteModule,
		MatTableModule,
		MatStepperModule,
		MatMenuModule,
		MatTabsModule,
		MatTooltipModule,
		MatCheckboxModule,
		MatRadioModule,
		MatDatepickerModule
	]
})

export class MaterialModule {}