<section id="login">
	<img class="logo mb40" src="/assets/dlogo_medium.png" alt="DVA Logo">
	<h2 class="title-lg mb40">Login</h2>
	<label class="subtitle-lg mb30">Informe os dados abaixo</label>

	<form>
		<inpt
			class="mb10 input-username"
			label="E-mail"
			[type]="'email'"
			suffix="check_circle"
			prefix="person"
			[(inputValue)]="email">
		</inpt>
		
		<inpt
			class="mb20 input-password"
			label="Senha"
			[type]="showPass ? 'text' : 'password'"
			[suffix]="showPass ? 'visibility_off' : 'visibility'"
			prefix="lock"
			(suffixCallback)="showPassword()"
			[(inputValue)]="password">
		</inpt>
		
		<btn
			icon="chevron_right"
			text="Acessar"
			color="blue"
			(callback)="login()"
			[loading]="loading">
		</btn>
	</form>
</section>