<section id="vehicles">
	<header class="page-header">
		<mat-icon class="material-icons-outlined back" (click)="goBack()" style="cursor: pointer;">keyboard_arrow_left</mat-icon>
		<div class="title">
			<mat-icon class="material-icons-outlined">account_balance</mat-icon>
			<span>Financeiro</span>
		</div>
		<div></div>
	</header>

	<section class="tools">
		<mat-form-field>
			<mat-label>Pesquisar</mat-label>
			<input matInput placeholder="Pesquisar" autocomplete="off" (keyup)="applyFilter($event)">
		</mat-form-field>
		<btn icon="add" text="Novo" color="blue" (callback)="openCreation()"></btn>
	</section>
	
	<section class="table-container">
		<table mat-table [dataSource]="dataSource">
		  	<ng-container matColumnDef="tac">
				<th mat-header-cell *matHeaderCellDef>TAC</th>
				<td mat-cell *matCellDef="let elmt">
					<div style="min-width: 100px;">
						{{ formatPrice(elmt.tac) }}
					</div>
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="installment">
				<th mat-header-cell *matHeaderCellDef>Parcelas</th>
				<td mat-cell *matCellDef="let elmt">
					<div style="min-width: 100px;">
						{{ elmt.installment }}
					</div>
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="note">
				<th mat-header-cell *matHeaderCellDef>Observação</th>
				<td mat-cell *matCellDef="let elmt">
					<div style="padding: 10px 20px 10px 0;">
						{{ elmt.note }}
					</div>
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="interestRate">
				<th mat-header-cell *matHeaderCellDef>Juros</th>
				<td mat-cell *matCellDef="let elmt" style="min-width: 100px;"> {{ elmt.interestRate + '% a.m.' }} </td>
		  	</ng-container>

		  	<ng-container matColumnDef="quotient">
				<th mat-header-cell *matHeaderCellDef style="text-align: center;">Quoeficiente</th>
				<td mat-cell *matCellDef="let elmt" style="min-width: 100px;text-align: center;"> {{ elmt.quotient ? elmt.quotient : '-' }} </td>
		  	</ng-container>

		  	<ng-container matColumnDef="options">
				<th mat-header-cell *matHeaderCellDef style="text-align: right;">Opções</th>
				<td mat-cell *matCellDef="let elmt" class="options-td">
					<button mat-icon-button [matMenuTriggerFor]="submenu">
						<mat-icon>more_horiz</mat-icon>
					</button>
					<mat-menu #submenu="matMenu">
						<button mat-menu-item (click)="openEdit(elmt)">Editar</button>
						<button mat-menu-item (click)="removeFinancial(elmt)">Remover</button>
					</mat-menu>
				</td>
		  	</ng-container>
	  
		  	<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</section>

	<section class="modal-container" *ngIf="showCreationModal">
		<div class="add-modal" id="mod1">
			<header>
				<mat-icon class="icon material-icons-outlined">commute</mat-icon>
				<h1>Novo parcelamento</h1>
				<mat-icon class="closing material-icons-outlined" (click)="cancelCreation()">close</mat-icon>
			</header>

			<div class="content">
				<form [formGroup]="firstFG" style="padding-top: 20px;">					
					<div class="form-container">
						<!-- TAC -->
						<mat-form-field>
							<mat-label>TAC</mat-label>
							<input matInput autocomplete="off" formControlName="tac" [allowNegativeNumbers]="false" prefix="R$ " mask="separator.2" decimalMarker=",">
						</mat-form-field>
						
						<!-- INSTALLMENTS -->
						<mat-form-field>
							<mat-label>Parcelas</mat-label>
							<input matInput autocomplete="off" formControlName="installment" mask="990">
						</mat-form-field>
					</div>
					<div class="form-container">
						<!-- QUOTIENT -->
						<mat-form-field>
							<mat-label>Coficiente</mat-label>
							<input matInput autocomplete="off" formControlName="quotient" mask="separator.6" decimalMarker=",">
						</mat-form-field>
						
						<!-- FEES -->
						<mat-form-field>
							<mat-label>Juros</mat-label>
							<input matInput autocomplete="off" formControlName="interestRate" [allowNegativeNumbers]="false" suffix="%" mask="separator.2" decimalMarker=",">
						</mat-form-field>
					</div>
					<div class="form-container">
						<!-- NOTE -->
						<mat-form-field>
							<mat-label>Observação</mat-label>
							<input matInput autocomplete="off" formControlName="note">
						</mat-form-field>
					</div>
				</form>
			</div>

			<footer>
				<button type="button" class="cancel-btn" mat-stroked-button (click)="cancelCreation()" [disabled]="loading">
					<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
					<span>Cancelar</span>
				</button>
				<button class="save-btn" mat-flat-button (click)="createFinancial()" [class.spinner]="loading" [disabled]="loading">
					<span [class.opac]="loading">Finalizar</span>
					<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
				</button>
			</footer>
		</div>
	</section>

	<section class="modal-container" *ngIf="showEditModal">
		<div class="add-modal" id="mod1">
			<header>
				<mat-icon class="icon material-icons-outlined">commute</mat-icon>
				<h1>Edição de Parcelamento</h1>
				<mat-icon class="closing material-icons-outlined" (click)="cancelEdit()">close</mat-icon>
			</header>

			<div class="content">
				<form [formGroup]="firstFG" style="padding-top: 20px;">					
					<div class="form-container">
						<!-- TAC -->
						<mat-form-field>
							<mat-label>TAC</mat-label>
							<input matInput autocomplete="off" formControlName="tac" [allowNegativeNumbers]="false" prefix="R$ " mask="separator.2" decimalMarker=",">
						</mat-form-field>
						
						<!-- INSTALLMENTS -->
						<mat-form-field>
							<mat-label>Parcelas</mat-label>
							<input matInput autocomplete="off" formControlName="installment" mask="990">
						</mat-form-field>
					</div>
					<div class="form-container">
						<!-- QUOTIENT -->
						<mat-form-field>
							<mat-label>Coficiente</mat-label>
							<input matInput autocomplete="off" formControlName="quotient" mask="separator.6" decimalMarker=",">
						</mat-form-field>
						
						<!-- FEES -->
						<mat-form-field>
							<mat-label>Juros</mat-label>
							<input matInput autocomplete="off" formControlName="interestRate" [allowNegativeNumbers]="false" suffix="%" mask="separator.2" decimalMarker=",">
						</mat-form-field>
					</div>
					<div class="form-container">
						<!-- NOTE -->
						<mat-form-field>
							<mat-label>Observação</mat-label>
							<input matInput autocomplete="off" formControlName="note">
						</mat-form-field>
					</div>
				</form>
			</div>

			<footer>
				<button type="button" class="cancel-btn" mat-stroked-button (click)="cancelEdit()" [disabled]="loading">
					<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
					<span>Cancelar</span>
				</button>
				<button class="save-btn" mat-flat-button (click)="updateFinancial()" [class.spinner]="loading" [disabled]="loading">
					<span [class.opac]="loading">Finalizar</span>
					<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
				</button>
			</footer>
		</div>
	</section>
</section>