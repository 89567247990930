import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { User } from '../_models/user';
import { environment } from 'src/environments/environment';
import { Router } from "@angular/router";
import { EventEmitterService } from "./event-emitter.service";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

	private currentUserSubject: BehaviorSubject<User>
	public currentUser: Observable<User>

	constructor(private http: HttpClient, private _router: Router) {
		this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')))
		this.currentUser = this.currentUserSubject.asObservable()
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value
	}

	login(email: string, password: string) {
		return this.http.post<any>(`${environment.apiUrl}/user/auth`, { username: email, password })
			.pipe(map(user => {
				if (user.accessToken && user.roles && user.roles.length > 0 && user.roles[0] == 'admin') {
					localStorage.setItem('currentUser', JSON.stringify(user))
					this.currentUserSubject.next(user)
				}

				return user
			}))
	}

	logout() {
		localStorage.clear()
		this.currentUserSubject.next(null)
		this._router.navigate([''])
		EventEmitterService.get('showLogin').emit(true)
		EventEmitterService.get('showMessage').emit(false)
		EventEmitterService.get('showLoading').emit(false)
	}

}