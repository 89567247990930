import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from './_helpers/auth.guard';
import { ExternalQuizComponent } from "./_pages/external-quiz/external-quiz.component";
import { FinancialComponent } from "./_pages/financial/financial.component";
import { HomeComponent } from "./_pages/home/home.component";
import { MainComponent } from "./_pages/main/main.component";
import { PreOrdersComponent } from "./_pages/pre-orders/pre-orders.component";
import { QuizzesComponent } from "./_pages/quizzes/quizzes.component";
import { SpecificationsComponent } from "./_pages/specifications/specifications.component";
import { UsersComponent } from "./_pages/users/users.component";
import { VehiclesComponent } from "./_pages/vehicles/vehicles.component";

const routes: Routes = [
	{ path: '', component: HomeComponent, canActivate: [AuthGuard], children: [
		{ path: '', redirectTo: 'main', pathMatch: 'full' },
		{ path: 'main', component: MainComponent, canActivate: [AuthGuard] },
		{ path: 'specifications', component: SpecificationsComponent, canActivate: [AuthGuard] },
		{ path: 'vehicles', component: VehiclesComponent, canActivate: [AuthGuard] },
		{ path: 'quizzes', component: QuizzesComponent, canActivate: [AuthGuard] },
		{ path: 'financial', component: FinancialComponent, canActivate: [AuthGuard] },
		{ path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
		{ path: 'pre-orders', component: PreOrdersComponent, canActivate: [AuthGuard] },
	]},
	{ path: 'external-quiz/:path', component: ExternalQuizComponent },
	{ path: '**', redirectTo: '' }
]

export const appRoutingModule = RouterModule.forRoot(routes, { useHash: true })