export class PreOrderSmall {
	  
	customerName: string
	vehicleModel: string
	vehicleVersion: string
	vehicleCategory: string
	status: string  // Abandoned = 1, Waiting = 2, InContact = 3, Rejected = 4, Closed = 5
	id: string
	createdAt: Date
	updatedAt: Date
	
	constructor(json?: any) {
		this.customerName = json.customerName
		this.vehicleModel = json.vehicleModel
		this.vehicleVersion = json.vehicleVersion
		this.vehicleCategory = json.vehicleCategory
		this.status = json.status
		this.id = json.id
		this.createdAt = new Date(json.createdAt.substr(6, 4), parseInt(json.createdAt.substr(3, 2)) - 1, json.createdAt.substr(0, 2))
		this.updatedAt = new Date(json.updatedAt.substr(6, 4), parseInt(json.updatedAt.substr(3, 2)) - 1, json.updatedAt.substr(0, 2))
    }

}