import { Pipe, PipeTransform } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Pipe({
    name: 'customFilter',
    pure: false
})
export class CustomFilterPipe implements PipeTransform {
    transform(items: any[], filterArgs: { field: string, filter: string }): any {
        return items.filter(x => {
            if (x instanceof FormGroup)
                return x.controls[filterArgs.field] && x.controls[filterArgs.field].value.toLowerCase().trim().indexOf(filterArgs.filter.toLowerCase().trim()) > -1
            return x[filterArgs.field] && x[filterArgs.field].toLowerCase().trim().indexOf(filterArgs.filter.toLowerCase().trim()) > -1
        })
    }
}