export class AlertConfig {
	icon: string
	type: string
	title: string
	content: string
	reload: boolean

	constructor(icon: string, type: string, title: string, content: string, reload: boolean = false) {
		this.icon = icon
		this.type = type
		this.title = title
		this.content = content
		this.reload = reload
	}
}