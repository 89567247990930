<div class="alert">
	<div class="head">
		<mat-icon class="icon" [ngClass]="{'error': type == 'error'}">{{ icon }}</mat-icon>
		<label class="title">{{ title }}</label>
		<div class="closing">
			<mat-icon (click)="closeClick()">close</mat-icon>
		</div>
	</div>
	<p class="content">{{ text }}</p>
	<div class="btns">
		<btn [text]="btnText" color="blue" noIcon="true" (click)="closeClick()"></btn>
	</div>
</div>
<div class="back"></div>