<section id="vehicles">
	<header class="page-header">
		<mat-icon class="material-icons-outlined back" (click)="goBack()" style="cursor: pointer;">keyboard_arrow_left</mat-icon>
		<div class="title">
			<mat-icon class="material-icons-outlined">person</mat-icon>
			<span>Usuários</span>
		</div>
		<div></div>
	</header>

	<section class="tools">
		<mat-form-field>
			<mat-label>Pesquisar</mat-label>
			<input matInput placeholder="Pesquisar" autocomplete="off" (keyup)="applyFilter($event)">
		</mat-form-field>
		<btn icon="add" text="Novo" color="blue" (callback)="openCreation()"></btn>
	</section>
	
	<section class="table-container">
		<table mat-table [dataSource]="dataSource">
		  	<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef>Nome</th>
				<td mat-cell *matCellDef="let elmt">
					<div>
						{{ elmt.name }}
					</div>
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef>E-mail</th>
				<td mat-cell *matCellDef="let elmt">
					<div>
						{{ elmt.email }}
					</div>
				</td>
		  	</ng-container>

		  	<ng-container matColumnDef="options">
				<th mat-header-cell *matHeaderCellDef style="text-align: right;">Opções</th>
				<td mat-cell *matCellDef="let elmt" class="options-td">
					<button mat-icon-button [matMenuTriggerFor]="submenu">
						<mat-icon>more_horiz</mat-icon>
					</button>
					<mat-menu #submenu="matMenu">
						<button mat-menu-item (click)="removeUser(elmt)">Remover</button>
					</mat-menu>
				</td>
		  	</ng-container>
	  
		  	<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</section>

	<section class="modal-container" *ngIf="showCreationModal">
		<div class="add-modal" id="mod1">
			<header>
				<mat-icon class="icon material-icons-outlined">person</mat-icon>
				<h1>Novo usuário</h1>
				<mat-icon class="closing material-icons-outlined" (click)="cancelCreation()">close</mat-icon>
			</header>

			<div class="content">
				<form [formGroup]="firstFG">
					<div class="img-container">
						<div class="empty" (click)="file.click()" *ngIf="!previewImage" [ngClass]="{'image-error': firstFG.get('image').invalid && firstFG.get('image').dirty}">
							<mat-icon class="material-icons-outlined">add</mat-icon>
							Imagem
						</div>
						<img [src]="previewImage" *ngIf="previewImage">
						<input type="file" id="inputFile" #file (change)="imagesPreview($event)" style="display: none;">
					</div>

					<div class="form-container">
						<!-- TAC -->
						<mat-form-field>
							<mat-label>Nome completo</mat-label>
							<input matInput autocomplete="off" formControlName="name">
						</mat-form-field>
						
						<!-- INSTALLMENTS -->
						<mat-form-field>
							<mat-label>E-mail</mat-label>
							<input matInput autocomplete="off" formControlName="email">
						</mat-form-field>
						
						<!-- NOTE -->
						<mat-form-field>
							<mat-label>Senha</mat-label>
							<input matInput autocomplete="off" formControlName="password" type="password">
						</mat-form-field>
					</div>
				</form>
			</div>

			<footer>
				<button type="button" class="cancel-btn" mat-stroked-button (click)="cancelCreation()" [disabled]="loading">
					<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
					<span>Cancelar</span>
				</button>
				<button class="save-btn" mat-flat-button (click)="createUser()" [class.spinner]="loading" [disabled]="loading">
					<span [class.opac]="loading">Finalizar</span>
					<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
				</button>
			</footer>
		</div>
	</section>
</section>