import { Injectable } from "@angular/core";
import { ConfirmComponent } from "@app/_common/confirm/confirm.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class ConfirmService {

  	constructor(private modalService: NgbModal) { }

  	public confirm(
		title: string,
		message: string,
		btnOkText: string = 'Confirmar',
		btnCancelText: string = 'Cancelar',
		dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
		
		const modalRef = this.modalService.open(ConfirmComponent, { size: dialogSize, animation: false })
		
		modalRef.componentInstance.title = title
		modalRef.componentInstance.message = message
		modalRef.componentInstance.btnOkText = btnOkText
		modalRef.componentInstance.btnCancelText = btnCancelText

    	return modalRef.result
  	}

  	public close(): void {
		this.modalService.dismissAll()
  	}

}