<section id="dashboard" [ngStyle]="{ 'background-image': currentStep == STEPS.INIT || currentStep == STEPS.FINAL ? 'url(' + '/assets/dashboard_background_1.png' + ')' : 'none' }">
	<loading *ngIf="loading"></loading>
	<div class="back" *ngIf="currentStep == STEPS.INIT || currentStep == STEPS.FINAL"></div>
	<header>
		<div style="display: flex;align-items: center;">
			<img class="mlogo" src="/assets/logo_merc.png" alt="Logo Mercedez">
			<h1 class="brand">Mercedes-Benz</h1>
		</div>
		<img class="dlogo" src="/assets/dlogo_medium.png" alt="Logo DVA" [ngClass]="{ 'logo-bottom': currentStep == STEPS.QUESTIONS }">
	</header>

	<footer *ngIf="currentStep == STEPS.INIT">
		<h2 class="mb-5" style="font-family: 'Playfair Display', serif;font-size: 28px;">Bem vindo ao configurador.</h2>
		<button class="primary-btn" mat-flat-button (click)="init()">
			<span>Clique aqui para começar</span>
			<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
		</button>
	</footer>

	<div class="notfound" *ngIf="currentStep == STEPS.NOTFOUND">
		<p style="font-family: 'Playfair Display', serif;"><i>Veículo não encontrado.</i></p>
		<p class="mb-0" style="font-family: 'Playfair Display', serif;"><i>Procure um revendedor.</i></p>
		<br>
		<button class="primary-btn" mat-flat-button (click)="reinit()">
			<span>Recomeçar</span>
		</button>
	</div>

	<div class="leadSection" *ngIf="currentStep == STEPS.LEAD1">
		<div class="head">
			<div class="back"></div>
			<h2>Informe seus dados para que entremos em contato.</h2>
		</div>

		<div class="container">
			<form [formGroup]="firstLeads" (ngSubmit)="goLead2()">
				<mat-form-field appearance="standard">
					<mat-label>Nome completo</mat-label>
					<mat-icon matPrefix class="material-icons-outlined">bookmark_border</mat-icon>
					<input type="text" matInput autocomplete="off" formControlName="name" (click)="setTimerToAskForPresence()" [appVirtualKeyboard]="''" (appVirtualKeyboardChange)="valueChange($event)" [isKeyboardDirectiveActive]="checkMobile()" [kboardType]="'alphaNumericType2'" />
					<mat-icon matSuffix class="material-icons-outlined" style="cursor: pointer;" *ngIf="name.value.length > 0" (click)="name.setValue('')">highlight_off</mat-icon>
				</mat-form-field>
	
				<mat-form-field appearance="standard">
					<mat-label>Número de telefone</mat-label>
					<mat-icon matPrefix class="material-icons-outlined">bookmark_border</mat-icon>
					<input inputmode="numeric" pattern="[0-9]*" type="text" matInput autocomplete="off" formControlName="phone" [mask]="phone.value.length <= 10 ? '(00) 0000-00009' : '(00) 0 0000-0000'" (click)="setTimerToAskForPresence()" [appVirtualKeyboard]="''" (appVirtualKeyboardChange)="valueChange2($event)" [isKeyboardDirectiveActive]="checkMobile()" [kboardType]="'numeric'" />
					<mat-icon matSuffix class="material-icons-outlined" style="cursor: pointer;" *ngIf="phone.value.length > 0" (click)="phone.setValue('')">highlight_off</mat-icon>
				</mat-form-field>
	
				<mat-form-field appearance="standard">
					<mat-label>E-mail</mat-label>
					<mat-icon matPrefix class="material-icons-outlined">bookmark_border</mat-icon>
					<input type="email" matInput autocomplete="off" formControlName="email" (click)="setTimerToAskForPresence()" [appVirtualKeyboard]="''" (appVirtualKeyboardChange)="valueChange3($event)" [isKeyboardDirectiveActive]="checkMobile()" [kboardType]="'alphaNumericType2'" />
					<mat-icon matSuffix class="material-icons-outlined" style="cursor: pointer;" *ngIf="email.value.length > 0" (click)="email.setValue('')">highlight_off</mat-icon>
				</mat-form-field>

				<div class="form-btns">
					<button type="button" class="clean-btn" mat-flat-button (click)="reinit()">
						<mat-icon class="material-icons-outlined" style="transform: rotate(180deg)">navigate_next</mat-icon>
						<span>Voltar</span>
					</button>
					<button type="submit" class="primary-btn" mat-flat-button>
						<span>Continuar</span>
						<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
					</button>
				</div>
			</form>
		</div>
	</div>

	<div class="leadSection" *ngIf="currentStep == STEPS.LEAD2">
		<div class="head">
			<div class="back"></div>
			<h2>Informe sua localidade.</h2>
		</div>

		<div class="container">
			<form [formGroup]="secondLeads" (ngSubmit)="goQuestion()">
				<mat-form-field appearance="standard">
					<mat-label>Estado</mat-label>
					<mat-icon matPrefix class="material-icons-outlined">bookmark_border</mat-icon>
					<mat-select formControlName="state" (click)="setTimerToAskForPresence()">
						<mat-option *ngFor="let st of states" [value]="st.state" (click)="setTimerToAskForPresence()">
							{{ st.state }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="standard">
					<mat-label>Cidade</mat-label>
					<mat-icon matPrefix class="material-icons-outlined">bookmark_border</mat-icon>
					<mat-select formControlName="city" [disabled]="!cities.length" (click)="setTimerToAskForPresence()">
						<mat-option *ngFor="let ct of cities" [value]="ct" (click)="setTimerToAskForPresence()">
							{{ ct }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<div class="lgpd">
					<mat-checkbox color="primary" formControlName="lgpd"></mat-checkbox> <span (click)="setLgpdCheckbox()">Li e aceito a </span><a href="https://www.compliancedva.com.br/lgpd/avisos-privacidade" target="_blank">Política de Privacidade</a>
				</div>

				<div class="form-btns">
					<button type="button" class="clean-btn" mat-flat-button (click)="goBackLead1()">
						<mat-icon class="material-icons-outlined" style="transform: rotate(180deg)">navigate_next</mat-icon>
						<span>Voltar</span>
					</button>
					<button type="submit" class="primary-btn" mat-flat-button>
						<span>Continuar</span>
						<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
					</button>
				</div>
			</form>
		</div>
	</div>

	<div class="questionsSection" *ngIf="currentStep == STEPS.QUESTIONS">
		<div class="back"></div>

		<div class="side">
			<div class="question" [formGroup]="currentOptionsFG">
				<label>{{ currentQuestion }}</label>
				
				<ul *ngIf="currentType == 'checkbox'">
					<li *ngFor="let opt of currentOptions; let i = index;" formArrayName="checkbox_options" (click)="selectCheckbox(i)">
						{{ opt.toLowerCase() == 'furgao' ? 'Furgão' : opt  }} <mat-checkbox color="primary" [formControlName]="i"></mat-checkbox>
					</li>
				</ul>
				
				<mat-radio-group *ngIf="currentType == 'radio'" formControlName="radio_options">
					<ul>
						<li *ngFor="let opt of currentOptions; let i = index;" (click)="selectRadio(opt)">
							{{ opt.toLowerCase() == 'furgao' ? 'Furgão' : opt }} <mat-radio-button color="primary" [value]="opt"></mat-radio-button>
						</li>
					</ul>
				</mat-radio-group>
			</div>
			<div class="form-btns">
				<button class="clean-btn" mat-flat-button (click)="prev()">
					<mat-icon class="material-icons-outlined" style="transform: rotate(180deg)">navigate_next</mat-icon>
					<span>Voltar</span>
				</button>
				<button class="primary-btn" mat-flat-button (click)="next()">
					<span>Continuar</span>
					<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
				</button>
			</div>
		</div>
	</div>
	
	<div class="resultSection" *ngIf="currentStep == STEPS.VEHICLE2">
		<div class="head">
			<div class="back"></div>
			<h2>Identificamos dois modelos pra você.</h2>
		</div>

		<div class="resultContainer">
			<div class="results">
				<div class="column left" style="border-right: 1px solid #333;">
					<img class="img" [src]="vehiclesToSelect[0].image" alt="Vehicle Image" />
					<label class="title">{{ vehiclesToSelect[0].model + ' ' + vehiclesToSelect[0].category }}</label>
					<div class="item" *ngFor="let spec of vehiclesToSelect[0].specifications; let i = index;">
						<div style="padding-right: 10px;"><strong>{{ spec.title }}</strong></div>
						<div style="text-align: right;">{{ spec.value }}</div>
					</div>
				</div>
				<div class="column right">
					<img class="img" [src]="vehiclesToSelect[1].image" alt="Vehicle Image" />
					<label class="title">{{ vehiclesToSelect[1].model + ' ' + vehiclesToSelect[1].category }}</label>
					<div class="item" *ngFor="let spec of vehiclesToSelect[1].specifications; let i = index;">
						<div style="padding-right: 10px;"><strong>{{ spec.title }}</strong></div>
						<div style="text-align: right;">{{ spec.value }}</div>
					</div>
				</div>
			</div>
			<div class="selection">
				<a href="javascript:void(0)" (click)="selectVechicle(0)">+ Visualizar detalhes e valores</a>
				<a href="javascript:void(0)" (click)="selectVechicle(1)">+ Visualizar detalhes e valores</a>
			</div>
		</div>

		<div class="btn-footer">
			<button class="clean-btn" mat-flat-button (click)="goQuestion()">
				<mat-icon class="material-icons-outlined" style="transform: rotate(180deg)">navigate_next</mat-icon>
				<span>Nova Simulação</span>
			</button>
		</div>
	</div>

	<div class="resultSection" *ngIf="currentStep == STEPS.VEHICLE1">
		<div class="head">
			<div class="back"></div>
			<h2>O modelo ideal pra você é:</h2>
		</div>

		<div class="resultContainer">
			<div class="results">
				<div class="column left" style="border-right: 1px solid #333;">
					<img class="img" [src]="selectedVehicle.image" alt="Vehicle Image" />
					<label class="title">{{ selectedVehicle.model + ' ' + selectedVehicle.category }}</label>
					<div class="item" *ngFor="let spec of selectedVehicle.specifications; let i = index;">
						<div style="padding-right: 10px;"><strong>{{ spec.title }}</strong></div>
						<div style="text-align: right;">{{ spec.value }}</div>
					</div>
				</div>
				<div class="column right" style="padding: 0 30px;">
					<div style="text-align: center;color: #00adef;font-size: 19px;margin-bottom: 10px;">{{ selectedVehicle.price | currency: 'BRL': true }}</div>
					<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;font-size: 12px;margin-bottom: 10px;">
						<span>À vista</span>
						<span>ou</span>
						<span>Entrada de</span>
					</div>
					<div style="text-align: center;font-size: 17px;border-bottom: 1px solid #333;margin-bottom: 10px;padding-bottom: 10px;">{{ selectedVehicle.entryPrice | currency: 'BRL': true }}</div>
					<div style="text-align: center;font-size: 12px;">
						mais parcelas:
						<br />
						<div *ngFor="let installment of selectedVehicle.installment" style="margin: 8px 0;font-size: 16px;">
							{{ installment.installment + 'x' }} {{ formatPriceValue(installment.installmentValue) }} <span style="color: #777;margin-left: 10px;">+ {{ installment.interestRate + '%' }} a.m.</span>
						</div>
					</div>

					<div style="color: #888;font-size: 13px;text-align: center;line-height: 16px;padding: 0 20px;margin-top: 25px;">
						Esta é apenas uma simulação, em que valores e condições podem ser alterados sem aviso prévio.
					</div>
				</div>
			</div>
		</div>

		<div class="btn-footer" style="display: flex;justify-content: space-between;width: 100%;">
			<div>
				<button class="clean-btn" mat-flat-button (click)="goBackVehiclesFound()" style="margin-right: 15px;">
					<mat-icon class="material-icons-outlined" style="transform: rotate(180deg)">navigate_next</mat-icon>
					<span>Voltar</span>
				</button>
				<button class="clean-btn" mat-flat-button (click)="goQuestion()">
					<mat-icon class="material-icons-outlined" style="transform: rotate(180deg)">navigate_next</mat-icon>
					<span>Nova Simulação</span>
				</button>
			</div>
			<button class="primary-btn" mat-flat-button (click)="finish()">
				<span>Solicitar contato</span>
				<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
			</button>
		</div>
	</div>

	<footer *ngIf="currentStep == STEPS.FINAL">
		<h2 class="mb-5" style="font-family: 'Playfair Display', serif;font-size: 28px;">Obrigado!</h2>
		<h2 class="mb-5" style="font-family: 'Playfair Display', serif;font-size: 28px;">Em breve entraremos em contato!</h2>

		<button class="primary-btn" mat-flat-button (click)="print()">
			<mat-icon class="material-icons-outlined" style="margin-right: 10px;">print</mat-icon>
			<span style="margin-right: 10px;">Imprimir Veículo Selecionado</span>
		</button>

		<button class="primary-btn" mat-flat-button (click)="goBackVehiclesFound()" style="margin-top: 20px;">
			<span>Voltar para Veículos Selecionados</span>
		</button>

		<button class="primary-btn" mat-flat-button (click)="goQuestion()" style="margin-top: 20px;">
			<span>Fazer uma Nova Simulação</span>
		</button>
	</footer>

	<div id="print-section" style="position: fixed;top: 100%;opacity: 0;">
		<div style="color: inherit;display: flex;justify-content: space-between;padding: 0 20px;margin: 20px 0;">
			<div style="display: flex;align-items: center;color: inherit;">
				<img src="http://dva.testa.la.s3-website.us-east-2.amazonaws.com/assets/logo_merc.png" alt="Logo Mercedez" style="height: 40px;margin-right: 20px;">
				<h1 style="color: inherit;font-size: 30px;font-family: 'Playfair Display';">Mercedes-Benz</h1>
			</div>
			<img src="http://dva.testa.la.s3-website.us-east-2.amazonaws.com/assets/dlogo_medium.png" alt="Logo DVA" style="filter: contrast(0%);height: 30px;margin-top: 20px;">
		</div>

		<div style="text-align: center;" style="color: inherit;display: flex;flex-direction: column;justify-content: center;align-items: center;">
			<span style="color: inherit;">O modelo ideal pra você é:</span>
			<br>
			<img [src]="selectedVehicle.image" alt="Vehicle Image" />
			<br>
			<h4 style="color: inherit;font-weight: bold;margin-bottom: 15px;">{{ selectedVehicle.model + ' ' + selectedVehicle.category }}</h4>
		</div>

		<div style="color: inherit;">
			<div style="color: inherit;">
				<div *ngFor="let spec of selectedVehicle.specifications; let i = index;" style="break-inside: avoid;color: inherit;display: flex;justify-content: space-between;padding: 12px 50px;border-bottom: 1px solid #f1f1f1;margin: 0 50px;">
					<div style="padding-right: 10px;color: inherit;font-weight: bold;">{{ spec.title }}</div>
					<div style="text-align: right;color: inherit;">{{ spec.value }}</div>
				</div>
			</div>
		</div>

		<div style="color: inherit;">
			<div style="break-inside: avoid;padding: 0 30px;color: inherit;margin-top: 40px;">
				<div style="text-align: center;color: #00adef;font-size: 19px;margin-bottom: 10px;">{{ selectedVehicle.price | currency: 'BRL': true }}</div>
				<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;font-size: 12px;margin-bottom: 10px;color: inherit;">
					<span style="color: inherit;">À vista</span>
					<span style="color: inherit;">ou</span>
					<span style="color: inherit;">Entrada de</span>
				</div>
				<div style="text-align: center;font-size: 17px;margin-bottom: 10px;padding-bottom: 10px;color: inherit;">{{ selectedVehicle.entryPrice | currency: 'BRL': true }}</div>
				<div style="text-align: center;font-size: 12px;">
					mais parcelas:
					<br />
					<div *ngFor="let installment of selectedVehicle.installment" style="margin: 8px 0;font-size: 16px;">
						{{ installment.installment + 'x' }} {{ formatPriceValue(installment.installmentValue) }} <span style="color: #777;margin-left: 10px;">+ {{ installment.interestRate + '%' }} a.m.</span>
					</div>
				</div>

				<div style="color: #888;font-size: 13px;text-align: center;line-height: 16px;padding: 0 20px;margin-top: 25px;">
					Esta é apenas uma simulação, em que valores e condições podem ser alterados sem aviso prévio.
				</div>
			</div>
		</div>
	</div>
</section>

<alert
	[icon]="alertConfig.icon"
	[type]="alertConfig.type"
	[title]="alertConfig.title"
	[text]="alertConfig.content"
	[reload]="alertConfig.reload"
	btnText="Entendi"
	*ngIf="alertConfig">
</alert>