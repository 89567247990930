import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlertConfig } from '@app/_common/alert/alert.model';
import { Financial } from '@app/_models/financial';
import { ConfirmService } from '@app/_services/confirm.service';
import { EventEmitterService } from '@app/_services/event-emitter.service';
import { FinancialService } from '@app/_services/financial.service';

@Component({
	selector: 'financial',
	templateUrl: './financial.component.html',
	styleUrls: ['./financial.component.scss'],
	encapsulation: ViewEncapsulation.None,
	host: { 'style': 'display: block; height: calc(100% - 50px);' }
})
export class FinancialComponent implements OnInit {

	displayedColumns = ['tac', 'installment', 'note', 'interestRate', 'quotient', 'options']
  	dataSource: any
	showCreationModal: boolean = false
	showEditModal: boolean = false
	loading: boolean = false
	currentFinancial: Financial
	
	firstFG: FormGroup
	
	constructor(
		private _router: Router,
		private _financialService: FinancialService,
		private _confirmService: ConfirmService) {

		this.firstFG = new FormGroup({
			'tac': new FormControl('', [Validators.required]),
			'installment': new FormControl('', [Validators.required]),
			'note': new FormControl('', [Validators.required]),
			'interestRate': new FormControl('', [Validators.required]),
			'quotient': new FormControl('', [Validators.required])
		})
	}

	get tac() { return this.firstFG.get('tac') }
	get installment() { return this.firstFG.get('installment') }
	get note() { return this.firstFG.get('note') }
	get interestRate() { return this.firstFG.get('interestRate') }
	get quotient() { return this.firstFG.get('quotient') }

	ngOnInit(): void {
		EventEmitterService.get('showLoading').emit(true)
		this.getFinancialData()
	}

	private getFinancialData() {
		this._financialService
			.list()
			.subscribe(response => {
				if (response.success)
					this.dataSource = new MatTableDataSource(response.data.map((x: any) => new Financial(x)))

				EventEmitterService.get('showLoading').emit(false)
			})
	}

	applyFilter(event: Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}

	openCreation() {
		this.showCreationModal = true
	}

	openEdit(financial: Financial) {
		this.showEditModal = true
		this.tac.setValue(financial.tac)
		this.installment.setValue(financial.installment)
		this.note.setValue(financial.note)
		this.interestRate.setValue(financial.interestRate)
		this.quotient.setValue(financial.quotient)
		this.currentFinancial = financial
	}

	cancelEdit() {
		this.showEditModal = false
		this.currentFinancial = null
		this.firstFG.reset('')
	}

	cancelCreation() {
		this._confirmService
			.confirm('Fechar Criação', 'Tem certeza que deseja fechar a criação?')
			.then((confirmed) => {
				if (confirmed) {
					this.showCreationModal = false
					this.firstFG.reset('')
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	createFinancial() {
		this.firstFG.markAllAsTouched()
		
		if (this.firstFG.valid) {
			this.loading = true

			let data = {...this.firstFG.value}

			data.tac = data.tac.toString().replace('.', ',')
			data.quotient = data.quotient.toString().replace('.', ',')
			data.interestRate = data.interestRate.toString().replace('.', ',')

			this._financialService
				.post(data)
				.subscribe(response => {
					if (!response.success) {
						EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
						return
					}

					this.dataSource.data.push(new Financial(response.data))
					this.dataSource._updateChangeSubscription()
					this.showCreationModal = false
					this.firstFG.reset('')
					this.loading = false
				},
				error => {
					this.loading = false
					console.error(error)
				})
		}
	}

	updateFinancial() {
		this.firstFG.markAllAsTouched()
		
		if (this.firstFG.valid && this.currentFinancial && this.currentFinancial.id) {
			this.loading = true

			let data = {...this.currentFinancial}

			data.tac = this.tac.value.toString().replace('.', ',')
			data.installment = this.installment.value
			data.note = this.note.value
			data.quotient = this.quotient.value.toString().replace('.', ',')
			data.interestRate = this.interestRate.value.toString().replace('.', ',')

			this._financialService
				.put(data)
				.subscribe(response => {
					if (!response.success) {
						EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
						return
					}

					let index = this.dataSource.data.findIndex((x: Financial) => x.id == data.id)

					if (index > -1) {
						this.dataSource.data[index] = new Financial(response.data)
						this.dataSource._updateChangeSubscription()
					}

					this.showEditModal = false
					this.firstFG.reset('')
					this.loading = false
				},
				error => {
					this.loading = false
					console.error(error)
				})
		}
	}

	removeFinancial(elmnt: Financial) {
		this._confirmService
			.confirm('Atenção!', 'Tem certeza que deseja remover o item financeiro?')
			.then((confirmed) => {
				if (confirmed) {
					let index = this.dataSource.data.findIndex((x: Financial) => x == elmnt)
			
					if (index > -1) {
						this._financialService
							.delete(elmnt.id)
							.subscribe(response => {
								if (!response.success) {
									EventEmitterService.get('showAlert').emit(new AlertConfig('highlight_off', 'error', 'Ocorreu um erro', response.errors.join(', ')))
									return
								}
	
								this.dataSource.data.splice(index, 1)
								this.dataSource._updateChangeSubscription()
							})
					}
				}
			})
			.catch(() => console.info('dismissed confirm'))
	}

	goBack() {
		this._router.navigate(['/'])
	}

	formatPrice(value: string) {
		if (value.indexOf(',') > -1)
			return 'R$ ' + value
		return 'R$ ' + value + ',00'
	}

}