export class Quiz {
	id:	string
	path: string
	title: string
	vehicleCategory: string
	specifications:  QuizSpecification[]
	updatedAt: Date
	createdAt: Date

	constructor(json?: any) {
        if (typeof json === 'string') {
            this.id = json
        } else if (json) {
            this.id = json.id
            this.path = json.path
            this.title = json.title
            this.vehicleCategory = json.vehicleCategory
			this.createdAt = json.createdAt
			this.updatedAt = json.updatedAt
			
			if (json.specifications && json.specifications.length)
				this.specifications = json.specifications.map((x: any) => new QuizSpecification(x))
        }
    }
}

export class QuizSpecification {
	id: string
	description: string
	index: number

	constructor(json?: any) {
        if (json) {
            this.id = json.id
            this.description = json.description
            this.index = json.index
        }
    }
}