export class User {

	id: string
    name: string
    email: string
    username: string
    password: string
    image: string
    inactivedAt: string
    permissions: Permission[]
	createdAt: string
    updatedAt: string

	constructor(json?: any) {
        if (typeof json === 'string') {
            this.id = json
        } else if (json) {
            this.id = json.id
            this.name = json.name
            this.email = json.email
            this.username = json.username
            this.password = json.password
            this.image = json.image
            this.inactivedAt = json.inactivedAt
            this.createdAt = json.createdAt
            this.updatedAt = json.updatedAt
            this.permissions = json.permissions
        }
    }

}

export class Permission {

    identifierName: string
    description: string
    category: string
    icon: string

}