<section id="vehicles">
	<header class="page-header">
		<mat-icon class="material-icons-outlined back" (click)="goBack()" style="cursor: pointer;">keyboard_arrow_left</mat-icon>
		<div class="title">
			<mat-icon class="material-icons-outlined">commute</mat-icon>
			<span>Veículos</span>
		</div>
		<div></div>
	</header>

	<section class="tools">
		<mat-form-field>
			<mat-label>Pesquisar</mat-label>
			<input matInput placeholder="Pesquisar" autocomplete="off" (keyup)="applyFilter($event)">
		</mat-form-field>
		<btn icon="add" text="Novo" color="blue" (callback)="openCreation()"></btn>
	</section>
	
	<section class="table-container">
		<table mat-table [dataSource]="dataSource">
		  	<ng-container matColumnDef="model">
				<th mat-header-cell *matHeaderCellDef>Modelo</th>
				<td mat-cell *matCellDef="let elmt"> {{ elmt.model }} </td>
		  	</ng-container>

		  	<ng-container matColumnDef="version">
				<th mat-header-cell *matHeaderCellDef>Versão</th>
				<td mat-cell *matCellDef="let elmt"> {{ elmt.version }} </td>
		  	</ng-container>

		  	<ng-container matColumnDef="category">
				<th mat-header-cell *matHeaderCellDef>Categoria</th>
				<td mat-cell *matCellDef="let elmt"> {{ getCategoryLabel(elmt.category) }} </td>
		  	</ng-container>

		  	<ng-container matColumnDef="options">
				<th mat-header-cell *matHeaderCellDef style="text-align: right;">Opções</th>
				<td mat-cell *matCellDef="let elmt" class="options-td">
					<button mat-icon-button [matMenuTriggerFor]="submenu">
						<mat-icon>more_horiz</mat-icon>
					</button>
					<mat-menu #submenu="matMenu">
						<button mat-menu-item (click)="showVehicle(elmt)">Detalhes</button>
						<button mat-menu-item (click)="removeVehicle(elmt)">Remover</button>
					</mat-menu>
				</td>
		  	</ng-container>
	  
		  	<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>
	</section>

	<section class="modal-container" *ngIf="showCreationModal">
		<div class="add-modal" id="mod1">
			<header>
				<mat-icon class="icon material-icons-outlined">commute</mat-icon>
				<h1>Novo veículo</h1>
				<mat-icon class="closing material-icons-outlined" (click)="cancelCreation()">close</mat-icon>
			</header>

			<div class="content">
				<mat-stepper [linear]="true" #stepper>
					<ng-template matStepperIcon="edit">
						<mat-icon>check</mat-icon>
					</ng-template>

					<!-- STEP 1 -->
					<mat-step [stepControl]="firstFG" label="Dados">
						<form [formGroup]="firstFG">
							<div class="img-container">
								<!-- [ngClass]="{'image-error': firstFG.get('image').invalid && firstFG.get('image').dirty}" -->
								<div class="empty" (click)="file.click()" *ngIf="!previewImage">
									<mat-icon class="material-icons-outlined">add</mat-icon>
									Imagem
								</div>
								<img [src]="previewImage" *ngIf="previewImage" alt="Imagem do Veículo">
								<input type="file" id="inputFile" #file (change)="imagesPreview($event)" style="display: none;">
								<button class="delete-img" matTooltip="Remover Imagem" (click)="removeImage()" *ngIf="previewImage">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
							
							<div class="form-container">
								<!-- MODEL -->
								<mat-form-field>
									<mat-label>Modelo</mat-label>
									<input matInput autocomplete="off" formControlName="model">
								</mat-form-field>
								
								<!-- VERSION -->
								<mat-form-field>
									<mat-label>Versão</mat-label>
									<input matInput autocomplete="off" formControlName="version">
								</mat-form-field>

								<!-- CATEGORY -->
								<mat-form-field>
									<mat-label>Categoria</mat-label>
									<mat-select formControlName="category">
										<mat-option *ngFor="let category of categories" [value]="category.value">
											{{ category.text }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<footer>
								<button class="save-btn" mat-flat-button matStepperNext (click)="markFirstDirty()">
									<span>Próximo</span>
									<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
								</button>
							</footer>
						</form>
					</mat-step>

					<!-- STEP 2 -->
					<mat-step [stepControl]="secondFG" label="Especificações">
						<form [formGroup]="secondFG" style="padding-top: 10px;">
							<div class="large-height">
								<mat-accordion>
									<mat-expansion-panel
										*ngFor="let spec of formArray.controls; let index = index;"
										[formGroup]="spec"
										[expanded]="spec.get('specificationId').value == '000'"
										[ngClass]="{'custom-spec': spec.get('specificationId').value == '000'}">

										<mat-expansion-panel-header>
											<mat-panel-title>
												{{ spec.get('specificationId').value == '000' ? 'Especificação Customizada ' + (spec.get('title').value ? '(' + spec.get('title').value + ')' : '') : spec.get('title').value }}
												<mat-icon class="material-icons-outlined delete" (click)="$event.stopPropagation();removeSpecification(index);">delete</mat-icon>
												<em *ngIf="spec.get('value').errors && spec.get('value').errors.required && spec.get('value').dirty" style="color: red;">* {{ spec.get('specificationId').value != '000' ? 'Selecione' : 'Insira' }} uma opção</em>
											</mat-panel-title>
										</mat-expansion-panel-header>

										<div class="form-container" style="display: block;width: 210px;"  *ngIf="spec.get('specificationId').value != '000'">
											<mat-form-field>
												<mat-label>Opções</mat-label>
												<mat-select formControlName="value">
													<mat-option *ngFor="let option of spec.get('options').value" [value]="option">
														{{ option }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>

										<div class="custom-spec-body" *ngIf="spec.get('specificationId').value == '000'">
											<mat-form-field floatLabel="always" style="width: 100%;">
												<mat-label>Especificação Customizada</mat-label>
												<input matInput autocomplete="off" formControlName="title">
											</mat-form-field>
											<mat-form-field floatLabel="always" style="width: 100%;margin-left: 20px;">
												<mat-label>Opção Customizada</mat-label>
												<input matInput autocomplete="off" formControlName="value">
											</mat-form-field>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
							</div>

							<div style="display: flex;justify-content: flex-end;">
								<button type="button" mat-button (click)="addNewSpec()">
									<mat-icon class="material-icons-outlined" style="color: #00adef;font-size: 20px;">add</mat-icon>
									<span style="color: #00adef;">Nova Especificação</span>
								</button>
							</div>

							<footer>
								<button type="button" class="cancel-btn" mat-stroked-button matStepperPrevious>
									<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
									<span>Anterior</span>
								</button>
								<button class="save-btn" mat-flat-button matStepperNext (click)="markTouched()">
									<span>Próximo</span>
									<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
								</button>
							</footer>
						</form>
					</mat-step>

					<!-- STEP 3 -->
					<mat-step [stepControl]="thirdFG" label="Financeiro">
						<form [formGroup]="thirdFG">
							<div class="form-container" style="margin-top: 40px;">
								<!-- VALUE -->
								<mat-form-field>
									<mat-label>Valor do veículo</mat-label>
									<input matInput autocomplete="off" formControlName="price" [allowNegativeNumbers]="false" prefix="R$ " mask="separator.2" decimalMarker=",">
								</mat-form-field>
								
								<!-- MINIMUM INTRO -->
								<mat-form-field>
									<mat-label>Entrada mínima</mat-label>
									<input matInput autocomplete="off" formControlName="entryPrice" [allowNegativeNumbers]="false" prefix="R$ " mask="separator.2" decimalMarker=",">
								</mat-form-field>
								
								<!-- ICMS -->
								<mat-form-field>
									<mat-label>ICMS</mat-label>
									<input matInput autocomplete="off" formControlName="icms">
								</mat-form-field>
							</div>

							<footer>
								<button type="button" class="cancel-btn" mat-stroked-button matStepperPrevious>
									<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
									<span>Anterior</span>
								</button>
								<button class="save-btn" mat-flat-button matStepperNext>
									<span>Próximo</span>
									<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
								</button>
							</footer>
						</form>
					</mat-step>

					<!-- FINAL STEP -->
					<mat-step label="Revisão">
						<div class="final-step">
							<div class="columns head">
								<div>
									Ficha técnica
								</div>
								<div>
									{{ firstFG.get('model').value }}
									{{ getCategoryLabel(firstFG.get('category').value) }}
									{{ firstFG.get('version').value }}
								</div>
							</div>
							<div class="image-container" *ngIf="previewImage">
								<img [src]="previewImage" alt="Imagem do Veículo" />
							</div>
							<div class="columns item" *ngFor="let spec of formArray.controls">
								<div>
									{{ spec.get('title').value }}
								</div>
								<div>
									{{ spec.get('value').value }}
								</div>
							</div>
							<div class="columns item">
								<div>
									Valor do Veículo
								</div>
								<div>
									{{ thirdFG.get('price').value | currency: 'BRL' }}
								</div>
							</div>
							<div class="columns item">
								<div>
									Entrada Mínima
								</div>
								<div *ngIf="thirdFG.get('entryPrice').value">
									{{ thirdFG.get('entryPrice').value | currency: 'BRL' }}
								</div>
								<div *ngIf="!thirdFG.get('entryPrice').value">
									-
								</div>
							</div>
							<div class="columns item">
								<div>
									ICMS
								</div>
								<div>
									{{ thirdFG.get('icms').value }}
								</div>
							</div>
						</div>

						<footer>
							<button type="button" class="cancel-btn" mat-stroked-button matStepperPrevious [disabled]="loading">
								<mat-icon class="material-icons-outlined">navigate_next</mat-icon>
								<span>Anterior</span>
							</button>
							<button class="save-btn" mat-flat-button (click)="createVehicle()" [class.spinner]="loading" [disabled]="loading">
								<span [class.opac]="loading">Finalizar</span>
								<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
							</button>
						</footer>
					</mat-step>
				</mat-stepper>
			</div>
		</div>
	</section>

	<section class="modal-container" *ngIf="showDetail">
		<div class="add-modal" id="mod2">
			<header>
				<mat-icon class="icon material-icons-outlined">commute</mat-icon>
				<h1>Veículo</h1>
				<mat-icon class="closing material-icons-outlined" (click)="cancelDetail()">close</mat-icon>
			</header>

			<div class="content">
				<mat-tab-group>
					<mat-tab label="Dados">
						<form [formGroup]="firstFG">
							<div class="img-container">
								<div class="empty" (click)="file.click()" *ngIf="!previewImage">
									<mat-icon class="material-icons-outlined">add</mat-icon>
									Imagem
								</div>
								<img [src]="previewImage" *ngIf="previewImage" alt="Imagem do Veículo">
								<input type="file" id="inputFile" #file (change)="imagesPreview($event)" style="display: none;">
								<button class="delete-img" matTooltip="Remover Imagem" (click)="removeImage()" *ngIf="previewImage">
									<mat-icon>delete</mat-icon>
								</button>
							</div>
							
							<div class="form-container">
								<!-- MODEL -->
								<mat-form-field>
									<mat-label>Modelo</mat-label>
									<input matInput autocomplete="off" formControlName="model">
								</mat-form-field>
								
								<!-- VERSION -->
								<mat-form-field>
									<mat-label>Versão</mat-label>
									<input matInput autocomplete="off" formControlName="version">
								</mat-form-field>

								<!-- CATEGORY -->
								<mat-form-field>
									<mat-label>Categoria</mat-label>
									<mat-select formControlName="category">
										<mat-option *ngFor="let category of categories" [value]="category.value">
											{{ category.text }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</form>
					</mat-tab>
					<mat-tab label="Especificações">
						<form [formGroup]="secondFG" style="padding-top: 10px;">
							<div class="large-height">
								<mat-accordion>
									<mat-expansion-panel
										*ngFor="let spec of formArray.controls; let index = index;"
										[formGroup]="spec"
										[expanded]="spec.get('specificationId').value == '000' || spec.get('specificationId').value == 'null'"
										[ngClass]="{'custom-spec': spec.get('specificationId').value == 'null' || spec.get('specificationId').value == '000'}">

										<mat-expansion-panel-header>
											<mat-panel-title>
												{{ spec.get('specificationId').value == '000' ? 'Especificação Customizada ' + (spec.get('title').value ? '(' + spec.get('title').value + ')' : '') : spec.get('title').value }}
												<mat-icon class="material-icons-outlined delete" (click)="$event.stopPropagation();removeSpecification(index);">delete</mat-icon>
												<i *ngIf="spec.get('value').errors && spec.get('value').errors.required && spec.get('value').dirty" style="color: red;">* {{ spec.get('specificationId').value != '000' ? 'Selecione' : 'Insira' }} uma opção</i>
											</mat-panel-title>
										</mat-expansion-panel-header>

										<div class="form-container" style="display: block;width: 210px;" *ngIf="spec.get('specificationId').value != null && spec.get('specificationId').value != '000'">
											<span>{{ spec.get('value').value }}</span>
										</div>

										<div class="custom-spec-body" *ngIf="spec.get('specificationId').value == null || spec.get('specificationId').value == '000'">
											<mat-form-field floatLabel="always" style="width: 100%;">
												<mat-label>Especificação Customizada</mat-label>
												<input matInput autocomplete="off" formControlName="title">
											</mat-form-field>
											<mat-form-field floatLabel="always" style="width: 100%;margin-left: 20px;">
												<mat-label>Opção Customizada</mat-label>
												<input matInput autocomplete="off" formControlName="value">
											</mat-form-field>
										</div>
									</mat-expansion-panel>
								</mat-accordion>
							</div>

							<div style="display: flex;justify-content: flex-end;">
								<button type="button" mat-button (click)="addNewSpec()">
									<mat-icon class="material-icons-outlined" style="color: #00adef;font-size: 20px;">add</mat-icon>
									<span style="color: #00adef;">Nova Especificação</span>
								</button>
							</div>
						</form>
					</mat-tab>
					<mat-tab label="Financeiro">
						<form [formGroup]="thirdFG">
							<div class="form-container" style="margin-top: 40px;">
								<!-- VALUE -->
								<mat-form-field>
									<mat-label>Valor do veículo</mat-label>
									<input matInput autocomplete="off" formControlName="price" [allowNegativeNumbers]="false" prefix="R$ " mask="separator.2" decimalMarker=",">
								</mat-form-field>
								
								<!-- MINIMUM INTRO -->
								<mat-form-field>
									<mat-label>Entrada mínima</mat-label>
									<input matInput autocomplete="off" formControlName="entryPrice" [allowNegativeNumbers]="false" prefix="R$ " mask="separator.2" decimalMarker=",">
								</mat-form-field>
								
								<!-- ICMS -->
								<mat-form-field>
									<mat-label>ICMS</mat-label>
									<input matInput autocomplete="off" formControlName="icms">
								</mat-form-field>
							</div>
						</form>
					</mat-tab>
					<mat-tab label="Revisão">
						<div class="final-step">
							<div class="columns head">
								<div>
									Ficha técnica
								</div>
								<div>
									{{ firstFG.get('model').value }}
									{{ firstFG.get('category').value }}
									{{ firstFG.get('version').value }}
								</div>
							</div>
							<div class="image-container" *ngIf="previewImage">
								<img [src]="previewImage" alt="Imagem do Veículo" />
							</div>
							<div class="columns item" *ngFor="let spec of formArray.controls">
								<div>
									{{ spec.get('title').value }}
								</div>
								<div>
									{{ spec.get('value').value }}
								</div>
							</div>
							<div class="columns item">
								<div>
									Valor do Veículo
								</div>
								<div>
									{{ thirdFG.get('price').value | currency: 'BRL' }}
								</div>
							</div>
							<div class="columns item">
								<div>
									Entrada Mínima
								</div>
								<div *ngIf="thirdFG.get('entryPrice').value">
									{{ thirdFG.get('entryPrice').value | currency: 'BRL' }}
								</div>
								<div *ngIf="!thirdFG.get('entryPrice').value">
									-
								</div>
							</div>
							<div class="columns item">
								<div>
									ICMS
								</div>
								<div>
									{{ thirdFG.get('icms').value }}
								</div>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>

			<footer>
				<button class="save-btn" mat-flat-button (click)="updateVehicle()" [class.spinner]="loading" [disabled]="loading">
					<span [class.opac]="loading">Salvar</span>
					<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
				</button>
			</footer>
		</div>
	</section>
</section>