<mat-expansion-panel [formGroup]="InnerFormGroup">
	<mat-expansion-panel-header>
		<mat-panel-title>
			{{ InnerFormGroup.get('title').value }} <mat-icon class="material-icons-outlined delete" (click)="$event.stopPropagation();deleteSpecification();">delete</mat-icon>
		</mat-panel-title>
	</mat-expansion-panel-header>

	<div class="form-container">
		<!-- QUESTION -->
		<mat-form-field>
			<mat-label>Questão</mat-label>
			<input matInput autocomplete="off" formControlName="question">
		</mat-form-field>

		<!-- TYPE -->
		<mat-form-field>
			<mat-label>Tipo</mat-label>
			<mat-select formControlName="selectedType">
				<mat-option *ngFor="let type of types" [value]="type.value">
					{{ type.text }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<!-- OPTIONS -->
		<mat-form-field>
			<mat-label>Opções</mat-label>
			<mat-chip-list #chipList aria-label="Seleção de opções" formControlName="options" [disabled]="OnlyView">
				<mat-chip *ngFor="let option of InnerFormGroup.get('options').value" [selectable]="false" (removed)="removeChip(option)">
					{{ option }} <mat-icon matChipRemove *ngIf="!OnlyView">close</mat-icon>
				</mat-chip>
				<input
					placeholder="Nova Opção"
					class="ng-pristine ng-invalid ng-invalid-required ng-touched"
					[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
					[matChipInputAddOnBlur]="true"
					[matChipInputFor]="chipList"
					(matChipInputTokenEnd)="addChip($event)"
					*ngIf="!OnlyView">
			</mat-chip-list>
		</mat-form-field>
	</div>

	<footer *ngIf="!OnlyView">
		<button class="save-btn" mat-flat-button (click)="updateSpecification()" [class.spinner]="loading" [disabled]="loading">
			<span [class.opac]="loading">Salvar</span>
			<mat-icon class="material-icons-outlined" [class.opac]="loading">navigate_next</mat-icon>
		</button>
	</footer>
</mat-expansion-panel>